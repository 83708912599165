import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StyledBlock from '../../../../Components/StyledBlocks/StyledBlock';
import CardLinks from '../../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../../Components/PageHeader/PageHeader';

function HistoryDisplay() {
  const linksFileName = 'AboutUs.json';
  const urlDomain = 'AboutUs';

  const { id } = useParams();
  const navigate = useNavigate();
  const fileName = 'HistoryList.json';

  

  useEffect(() => {
    const loadData = async () => {
    Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
      const result = response.data;
      setHistory([result[id]]);
    })
  };
    loadData();
  }, [id])

  const [history, setHistory] = useState([]);
  return (
    <>
    <PageHeader header1={history[0]?.title} imgArr="history" alt='formPage' />
    <div style={{minHeight: '100vh', marginTop: '100px'}}>
    <StyledBlock fileName={undefined} block={history} historyItem={true}/>
      <hr style={{ margin: '25px' }} /> 
      <button className='btn' style={{ margin: '25px' }} onClick={() => { navigate("/AboutUs/History") }}>Back</button>
    </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </>
  )
}

export default HistoryDisplay