import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import GetPDF from '../../../Components/GetPDF/GetPDF';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import CardLinks from '../../../Components/CardLinks/CardLinks';

function BillingCalendar() {
  const header1 = 'Mark Your Calendar'
  const paragraph1 = 'Keep Up to Date with Our Billing Schedule';
  const linksFileName = 'News.json';
  const urlDomain = 'News';

  let newDate = new Date();
  let month = newDate.getMonth() + 1;
  
  const fileName = 'BillingCalendar.json';
  const [recent, setRecent] = useState('');
  
  useEffect(() => {
    const loadData = async () => {
      try {
        await Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
          const result = response.data;
          const reports = result[result.length - 1];
          setRecent(reports.file);
        })
      } catch (err) {
        console.log(err)
      }
    };
    loadData()
  }, []);
  return (
    <>
    <PageHeader header1={header1} paragraph1={paragraph1} imgArr="calendar" alt='formPage' />
    <div style={{ marginTop: '100px' }}>
      <GetPDF fileName='BillingCalendar.json' controls={true} size={1000} page={month} recent={recent}/>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </div>
    </>
  )
}

export default BillingCalendar