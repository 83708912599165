import { useEffect } from 'react';
import { useLocation, /* useNavigate */ } from 'react-router-dom';

export default function GoToTop() {
    /* const navigate = useNavigate(); */
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        const hash = routePath.hash;
        const removeHashCharacter = (str) => {
            const result = str.slice(1);
            return result;
        };
        if (!hash) {
            onTop()
        } else {
            let element = document.getElementById(removeHashCharacter(hash));
            console.log(element);
            if (element) {
                element.scrollIntoView({
                    inline: "nearest"
                })
            } /* else {
                console.log("ID NOT FOUND")
                navigate("/PageNotFound")
            } */
        }
    }, [routePath]);

    return null;
}