import React from 'react'
import CardLinks from '../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import StyledBlock from '../../../Components/StyledBlocks/StyledBlock';
import Toilet from '../../../Assets/images/NYKAYH2O/toilet.png';
import NYKAYH2O from '../../../Components/NYKAYH2O/NYKAYH2O';

function Sewer() {
    const header1 = 'Sewer Problems'
    const paragraph1 = 'Before you call the plumber, Save Money by Calling Searcy Water First!';
    const linksFileName = 'News.json';
    const styledBlockFileName1 = 'StyledBlocks/Sewer/Sewer1.json';
    const styledBlockFileName2 = 'StyledBlocks/Sewer/Sewer2.json';
    const NYKtitle = 'Are They Really Flushable?';
    const NYKparagraph = "Many wipes and products are labeled as flushable, but that does not mean they are safe for disposal in the toilet. The only product that Searcy Water Utilities recognizes as flushable and safe for our sewer systems is toilet paper.";
    const urlDomain = 'News';
    return (
        <div className='newsPage'>
            <PageHeader header1={header1} paragraph1={paragraph1} imgArr="sewer" alt='subPage'/>
            <div className='subPageContainer'>
                <StyledBlock fileName={styledBlockFileName1}/>
            <NYKAYH2O id='Flushable_Wipes?' direction={true} title={NYKtitle} img={Toilet} alt="Toilet being flushed" paragraph={NYKparagraph}/>
                <StyledBlock fileName={styledBlockFileName2}/>
            </div>

            <div className='aboutUs'>
                <CardLinks urlDomain={urlDomain} fileName={linksFileName}/>
            </div>
        </div >
    )
}

export default Sewer
