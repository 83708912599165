import React, { useEffect, useState } from "react";
import CustomSlider from "../../../Components/Slider/CustomSlider";
import DiamondLine from "../DiamondLine/DiamondLine";
import Axios from "axios";

const ConservationTips = () => {

  useEffect(() => {
    loadData();
  }, [])

  const [tips, setTips] = useState([]);
  const fileName = 'StyledBlocks/MultiSelection/ConservationTips.json';

  const loadData = async () => {
    try {
    Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
      const result = response.data;
      if (result.length !== 0) {
      setTips(result);
      }
      else {
        console.log("Tips Empty!")
      }
    })
  } catch (error) {
    console.log(error)
  }
  };
  return (
    <div>
      <DiamondLine title="Conservation Tips" reverse={false} />
      <div style={{ backgroundColor: "#E3E6F0", background: 'linear-gradient(180deg, #E3E6F000 0%, #E3E6F0FF 10%, #E3E6F0FF 90%, #E3E6F000 100%)', padding: "70px 0px 230px", }}>
        <CustomSlider type="tip" fade={false} src={tips} height="600"/>
      </div>
    </div>
  );
};

export default ConservationTips;
