import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, FormLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MuiTelInput } from 'mui-tel-input'
import { format, parseISO } from 'date-fns';
import ReCAPTCHA from "react-google-recaptcha";

import Axios from 'axios'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FormSX } from './FormSX.jsx';
import './FormCSS.css';
import CardLinks from '../../../Components/CardLinks/CardLinks.jsx';
import PageHeader from '../../../Components/PageHeader/PageHeader.jsx';

function LeakForm() {
  const header1 = 'Report a Service Leak'
  const paragraph1 = 'If you suspect that there is a leak, please report it on the form below';
  const linksFileName = 'Services.json';
  const urlDomain = 'Services';

  const [recaptchaKey, setRecaptchaKey] = useState();
  let today = format(new Date(), 'yyyy-MM-dd');
  const [emergency, setEmergency] = useState('no')
  const [contactName, setContactName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [serviceAddress, setServiceAddress] = useState('');
  const [startDate, setStartDate] = useState(today + 'T08:00');
  const [emailAddress, setEmailAddress] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [onProperty, setOnProperty] = useState('no');
  const [inHouse, setInHouse] = useState('no');
  const [contactInfo, setContactInfo] = useState('no');
  const [addInfo, setAddInfo] = useState('');

  const [sentData, setSentData] = useState([]);

  useEffect(() => {
    setSentData(JSON.parse(
      `{
        "contactName" : "${contactName}",
        "phoneNumber" : "${phoneNumber}",
        "serviceAddress" : "${serviceAddress}",
        "startDate" : "${startDate}",
        "emailAddress" : "${emailAddress}",
        "phoneNumber" : "${phoneNumber}",
        "onProperty" : "${onProperty}",
        "contactInfo" : "${contactInfo}",
        "inHouse" : "${inHouse}", 
        "addInfo" : "${addInfo}",
        "emergency" : "${emergency}"
        }`)
    )
  }, [
    contactName,
    phoneNumber,
    serviceAddress,
    startDate,
    emailAddress,
    onProperty,
    contactInfo,
    inHouse,
    addInfo,
    emergency
  ]);

  const runRecaptcha = async () => {
    try {
      await Axios.get("https://searcywaterutilitiesservice.onrender.com/api/recaptcha").then((response) => {
        const result = response.data
        setRecaptchaKey(result)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    runRecaptcha()
  }, [])

  const phoneChange = (newValue) => {
    setPhoneNumber(newValue);
  }

  function reCaptcha() {
    setCaptchaVerified(!captchaVerified);
  }

  const sendData = async () => {
    console.log("sending data")
    Axios.post("https://searcywaterutilitiesservice.onrender.com/leakMail", { sentData }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        alert("Thank you for submitting your form, we have received it and will get back to you soon!")
        window.location.reload()
      } else {
        alert("There was an error trying to send your form, please try again later or call our office")
      }
    })
  }

  const { handleSubmit } = useForm();
  const onSubmit = () => {
    if (phoneNumber.length < 7) {
      alert("Phone number is required!")
    }
    else {
    sendData();
    }
  }

  return (
    <div>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="leakReportForm" alt='formPage' />
      <div className='ServiceForm'>
        <Paper elevation={10} sx={FormSX.paperStyle}>
          <form id='leakForm' onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={FormSX.form}>
              <Typography variant="h1">Leak Report Form</Typography>
              <hr style={FormSX.headerLine} />
              <Typography variant='p'>Got a leak? Our dedicated team is here to assist you in addressing any water leak concerns promptly and efficiently. With our convenient online platform, reporting a leak has never been easier. By using this form, you are helping us maintain the integrity of our water supply and ensuring a sustainable future for our community.</Typography>
              <Typography variant='p'>To report a leak, simply fill out the form below with accurate and detailed information about the location and nature of the leak. Our skilled technicians will then assess the situation and take immediate action to rectify the issue.</Typography>
              <Typography variant='p'>At Searcy Water Utilities, we understand the importance of <a href='/News/Conservation'>water conservation</a> and the impact that leaks can have on our environment and resources. By working together, we can safeguard our precious water supply for generations to come. We appreciate your commitment to a sustainable future and look forward to serving you with excellence. Report a leak today and be a part of our ongoing efforts to preserve and protect our valuable water resources.</Typography>
              <Typography variant="p">Thank you for providing us with information that may help to conserve water. We encourage everyone to please report anything that appears to be a known or suspected water leak in our system. Please fill out the form below.</Typography>
              
              <div className='formContainer'>
                <FormLabel style={FormSX.FormLabel} id="emergency-label">
                  Is this an emergency?
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"no"}
                  name="emergency-radio-buttons"
                  onChange={(e) => { setEmergency(e.target.value) }}>
                  <FormControlLabel value='yes' control={<Radio required={true} />} label="Yes" />
                  <FormControlLabel value='no' control={<Radio required={true} />} label="No" />
                </RadioGroup>
              </div>
              
              <div className='formContainer' >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={8} sx={FormSX.GridItem}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="leakAddress"
                      label="Leak Address"
                      placeholder="Enter Address of Leak"
                      required
                      onChange={(e) => { setServiceAddress(e.target.value) }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={FormSX.GridItem}>
                    <LocalizationProvider
                      sx={{ flexShrink: 1 }}
                      dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        required
                        openTo="day"
                        label="Date Leak Found"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e)
                        }}
                        renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                        maxDate={parseISO(`${today}`)}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </div>              

              <div className='formContainer'>
                <FormLabel style={FormSX.FormLabel} id="leakOnProp-label">
                  Is the leak on your property?
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"no"}
                  name="address-radio-buttons"
                  onChange={(e) => { setOnProperty(e.target.value) }}>
                  <FormControlLabel value='yes' control={<Radio required={true} />} label="Yes" />
                  <FormControlLabel value='no' control={<Radio required={true} />} label="No" />
                </RadioGroup>
              </div>

              <div className='formContainer'>
                <FormLabel style={FormSX.FormLabel} id="inHouse-label">
                  Is the leak in a structure (house, shed, etc.)?
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"no"}
                  name="address-radio-buttons"
                  onChange={(e) => { setInHouse(e.target.value) }}>
                  <FormControlLabel value='yes' control={<Radio required={true} />} label="Yes" />
                  <FormControlLabel value='no' control={<Radio required={true} />} label="No" />
                </RadioGroup>
              </div>

              <div className='formContainer'>
                <FormLabel sx={{ ...FormSX.FormLabel, marginBottom: '10px' }} id="addInfo-Label">
                  Any additional information that may help locate the leak?
                </FormLabel>
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  multiline
                  minRows={4}
                  maxRows={4}
                  onChange={(e) => { setAddInfo(e.target.value) }}
                />
              </div>

              <div className='formContainer'>
                <FormLabel style={FormSX.FormLabel} id="contact-label">
                  May we contact you for additional information, if needed?
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"no"}
                  name="contact-buttons"
                  onChange={(e) => { setContactInfo(e.target.value) }}>
                  <FormControlLabel value='yes' control={<Radio required={true} />} label="Yes" />
                  <FormControlLabel value='no' control={<Radio required={true} />} label="No" />
                </RadioGroup>

                {(contactInfo === 'yes') ?
                  <div>
                    <Grid container spacing={1}>
                      <Grid item sx={FormSX.input} md={8} sm={12}>
                        <TextField
                          sx={{ width: '100%', marginRight: '10px' }}
                          id="contactName"
                          label="Name"
                          placeholder="Enter Name"
                          onChange={(e) => { setContactName(e.target.value) }}
                        />
                      </Grid>
                      <Grid item sx={FormSX.input} md={4} sm={12}>
                        <MuiTelInput
                          sx={{ width: '100%' }}
                          disableDropdown
                          onlyCountries={'US'}
                          label="Phone Number"
                          onChange={phoneChange}
                          value={phoneNumber} />
                      </Grid>

                      <Grid item sx={FormSX.input} xs={12}>
                        <TextField
                          sx={{ marginBottom: '15px' }}
                          type='email'
                          fullWidth
                          id="email"
                          label="Email Address"
                          placeholder="Enter Email Address"
                          onChange={(e) => { setEmailAddress(e.target.value) }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  : null}
                {(recaptchaKey !== undefined) ?
                    <ReCAPTCHA
                      sitekey={recaptchaKey}
                      onChange={reCaptcha}
                    />
                  : null }
              </div>
              <Button
                disabled={!captchaVerified}
                id="submit"
                variant="contained"
                style={{ marginTop: 10 }}
                type='submit' color='primary'
                fullWidth
              >Submit</Button>
            </FormControl>
          </form>
        </Paper>
      </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain} />
    </div>
  )
}

export default LeakForm