import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { TipCardSX } from './TipCardSX';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TipCard = (props) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('1380'));
  const smTablet = useMediaQuery(theme.breakpoints.down('750'));
  const mobile = useMediaQuery(theme.breakpoints.down('600'));
  const smMobile = useMediaQuery(theme.breakpoints.down('480'));
  return (
    <Card sx={{...TipCardSX.card, ...tablet ? {...TipCardSX.tabletCard} : '', ...smTablet ? {...TipCardSX.smTabletCard} : '', ...mobile ? {...TipCardSX.mobileCard} : '', ...smMobile ? {...TipCardSX.smMobileCard} : ''}}>
      <CardMedia
        component="img"
        alt={props.data.alt}
        image={`https://searcywaterutilitiesservice.onrender.com/images/StyledBlocks/${props.data.img}`}
        sx={{...TipCardSX.img, ...tablet ? {...TipCardSX.tabletImg} : '', ...smTablet ? {...TipCardSX.smTabletImg} : '', ...mobile ? {...TipCardSX.mobileImg} : '', ...smMobile ? {...TipCardSX.smMobileImg} : ''}}
        loading='lazy'
      />
      
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={TipCardSX.text} >
        {props.data.name}
        </Typography>
          {props.data.about.map((subtext, key) => {
            return (
            <Typography variant="body2" sx={{color: '#444', padding: '12px'}} key={key}>{subtext[0].text}</Typography>
              )})}
      </CardContent>
    </Card>
  )
}

export default TipCard