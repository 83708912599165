import React from 'react'
import CardLinks from '../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import StyledBlock from '../../../Components/StyledBlocks/StyledBlock';

function Board() {
    const header1 = 'Board of Public Utilities'
    const paragraph1 = 'Created September 13, 1948 via city ordinance #305';
    const linksFileName = 'AboutUs.json';
    const styledBlockFileName = 'StyledBlocks/Board.json';
    const urlDomain = 'AboutUs';
  return (
    <div className='aboutUs board'>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="board" alt='subPage' />
            <div className='subPageContainer'>
                <StyledBlock fileName={styledBlockFileName}/>
            </div>
      
      <div className='aboutUs'>
        <CardLinks urlDomain={urlDomain} fileName={linksFileName}/>
        </div>
      </div> 
  )
}

export default Board