import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
const root = ReactDOM.createRoot(document.getElementById('root'));



const theme = createTheme({
  palette: {
    primary: {
      main: "#282872"
    },
    secondary: {
      main: "#0a9045"
    },
    
  },
  typography: {
    inika: {
      fontFamily: "Inika, serif",
      fontSize: "1.5rem",
    },
    h2: {
      fontFamily: "Inika, serif",
      fontSize: "2rem",
    },
    h4: {
      fontFamily: "Inika, serif",
      fontSize: "1.2rem"
    }
  }
})

root.render(
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
