import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './HamburgerMenuCSS.css'

const HamburgerMenu = (props) => {
      const hamburgerMenuRef = useRef(null);
      const closeHamburgMenu = (e)=>{
            if(hamburgerMenuRef.current && props.active && !hamburgerMenuRef.current.contains(e.target)){
              props.setActive(false);
            }
      }
      document.addEventListener('mousedown',closeHamburgMenu)
  return (
      <div ref={hamburgerMenuRef} className='hamDiv'>
            {/* Hamburger Menu Animation */}
            <div className={`menuSVGContainer ${props.active ? 'active' : ''}`}>
      <svg className={`ham hamRotate ham8 ${props.active ? 'active' : ''}`} viewBox="0 0 100 100" width={40} onClick={() => {
            props.setActive(!props.active);
      }}>
        <path className="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
        <path className="line middle" d="m 30,50 h 40" />
        <path className="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
      </svg></div>
      <div ref={hamburgerMenuRef} className={`hamburgerMenu container ${props.active ? 'active' : ''}`}>
            <nav onClick={() => {
            props.setActive(false);
      }}>
                  <ol style={{ listStyle: 'none' }}>
                        <li className='navLinkContainer' >
                              <Link className={`${props.disablePayments ? 'disabledBtn': ''}`} onClick={() => {
                                    if (!props.disablePayments){
                                          props.handlePayments()
                                    }}}>
                                          Payments
                              </Link>
                        </li>
                        <li className='navLinkContainer' >
                              <Link to='/Services'>Services</Link>
                        </li>
                        <li className='navLinkContainer' >
                              <Link to='/News'>News & Info</Link>
                        </li>
                        <li className='navLinkContainer' >
                              <Link to='/AboutUs'>About Us</Link>
                        </li>
                        <li className='navLinkContainer' >
                              <Link to='/News/Conservation'>Water Conservation</Link>
                        </li>
                        <li className='navLinkContainer' >
                              <Link to='/AboutUs/History'>History</Link>
                        </li>
                        <li className='navLinkContainer' >
                              <a href="https://searcywater.maps.arcgis.com/apps/webappviewer/index.html?id=b0da5f679c5446308730235c9e328c4b" target="_blank" rel="noreferrer">GIS Mapping</a>
                        </li>
                  </ol>
            </nav>
      </div>
    </div>
  )
}
HamburgerMenu.propTypes = {
active: PropTypes.bool.isRequired,
setActive: PropTypes.func.isRequired,
handlePayments: PropTypes.func.isRequired,
disablePayments: PropTypes.bool.isRequired,
}

export default HamburgerMenu
