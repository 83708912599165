
import Axios from 'axios';
import "./App.css";
import Homepage from "./Pages/Homepage/Homepage";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import AdminPortal from "./Pages/AdminPortal/AdminPortal";
import AdminForms from "./Pages/AdminPortal/AdminForms";
import History from "./Pages/About/History/History";
import ContactUs from "./Pages/About/Contact/ContactUs";
import About from "./Pages/About/AboutUs";
import News from "./Pages/NewsInfo/News";
import ConsumerConfidenceReport from "./Pages/NewsInfo/NewsPages/ConsumerConfidenceReport";
import BillingCalendar from "./Pages/NewsInfo/NewsPages/BillingCalendar";
import Rates from "./Pages/NewsInfo/NewsPages/Rates";
import WaterTreatment from "./Pages/NewsInfo/NewsPages/WaterTreatment";
import WasteWaterTreatment from "./Pages/NewsInfo/NewsPages/WasteWaterTreatment";
import Sewer from "./Pages/NewsInfo/NewsPages/Sewer";
import Conservation from "./Pages/NewsInfo/NewsPages/Conservation/Conservation";
import HistoryDisplay from "./Pages/About/History/HistoryDisplay/HistoryDisplay";
import Services from "./Pages/Services/Services";
// import NewCustomerForm from "./Pages/Services/ServicePages/NewCustomerForm";
// import TransferForm from "./Pages/Services/ServicePages/TransferForm";
// import TerminationForm from "./Pages/Services/ServicePages/TerminationForm";
import BankDraftForm from "./Pages/Services/ServicePages/BankDraftForm";
import MailChangeForm from "./Pages/Services/ServicePages/MailChangeForm";
import LeakForm from "./Pages/Services/ServicePages/LeakForm";
import Board from "./Pages/About/Board/Board";
import Administation from "./Pages/About/Administration/Administation";
import GoToTop from "./Components/GoToTop";
import MessageBar from "./Components/MessageBar/MessageBar.js";
import PageNotFound from './Pages/PageNotFound';

import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  useState, useEffect,
  // useCallback 
} from "react";
import { AuthProvider } from "./Components/Auth";
import { RequireAuth } from "./Components/RequireAuth";



function App() {
  const [data, setData] = useState([]);
  const fileName = 'HolidayMaint.json';
  const [disablePayments, setDisablePayments] = useState(false);
  /* const [minimize, setMinimize] = useState(true);
  const [maximize, setMaximize] = useState(true); */

  useEffect(() => {
    const getData = async () => {
      try {
        Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
          const result = response.data
          setData(result);
        })
      } catch (err) {
        console.log(err)
      }
    }
    getData();
  }, [])

  /* const memoizedScrollFunction = useCallback(() => {
    const navLinks = document.getElementsByClassName("navLink");
    const paymentsBtn = document.getElementsByClassName("payBill btn");

    if (document.body.scrollTop >= 150 || document.documentElement.scrollTop >= 150) {
      if (minimize){
        document.getElementById("topNav").style.height = "50px";
        document.getElementById("messageBar").style.marginTop = "51px";
        document.getElementById("logo").style.height = "50px";
        document.getElementsByClassName("logo")[0].style.height = "50px";
        document.getElementsByClassName("links")[0].style.height = "50px";
        document.getElementsByClassName("menuSVGContainer")[0].style.height = "40px";
        document.getElementsByClassName("menuSVGContainer")[0].style.width = "40px";
        document.getElementsByClassName("hamburgerMenu")[0].style.top = "5px";
        document.getElementsByClassName("hamburgerMenu container")[0].style.top = "6.6px";
        for (let i = 0; i < navLinks.length; i++) {
          navLinks[i].style.padding = "10.2px 30px";
        }
        paymentsBtn[0].style.padding = "8px 20px";
        paymentsBtn[0].style.fontSize = "1rem";
        setMinimize(false);
        setMaximize(true);
      }
    } else  {
      if (maximize){
        document.getElementById("topNav").style.height = "80px";
        document.getElementById("messageBar").style.marginTop = "81px";
        document.getElementById("logo").style.height = "80px";
        document.getElementsByClassName("logo")[0].style.height = "80px";
        document.getElementsByClassName("links")[0].style.height = "80px";
        document.getElementsByClassName("menuSVGContainer")[0].style.height = "50px";
        document.getElementsByClassName("menuSVGContainer")[0].style.width = "50px";
        document.getElementsByClassName("hamburgerMenu")[0].style.top = "10px";
        document.getElementsByClassName("hamburgerMenu container")[0].style.top = "21.6px";
        for (let i = 0; i < navLinks.length; i++) {
          navLinks[i].style.padding = "26px 30px";
        }
        paymentsBtn[0].style.padding = "15px 20px";
        paymentsBtn[0].style.fontSize = "1.125rem";
        setMinimize(true);
        setMaximize(false);
      }
    }
  }, [maximize, minimize]); 

  useEffect(() => {
    // When the user scrolls down 150px from the top of the document, resize the header's size
    window.onscroll = function() {memoizedScrollFunction()};
    
  }, [memoizedScrollFunction]) */

  useEffect(() => {
    if (data.length > 0) {
      let maintenance = data[1].maintenance
      let override = data[2].override
      if (maintenance || override) {
        setDisablePayments(true)
      }
    }
  }, [data])

  return (

    <AuthProvider>
      <Router>
        <GoToTop />
        <Header disablePayments={disablePayments} />
        <div className='pageBackground'>
          <div className='pageForeground'>
            <MessageBar data={data} />
            <div>
              <div className="page-container">
                <div className="content-wrap">
                  <Routes>
                    <Route path="/" exact element={<Homepage />} />
                    <Route path="/AdminPortal" exact element={<AdminPortal />} />
                    <Route
                      path="/AdminForms"
                      exact
                      element={
                        <RequireAuth>
                          <AdminForms />
                        </RequireAuth>
                      }
                    />
                    <Route path="/AboutUs" exact element={<About />} />
                    <Route path="/AboutUs/History" exact element={<History />} />
                    <Route path="/History/:id" element={<HistoryDisplay />} />
                    <Route path="/AboutUs/Board" exact element={<Board />} />
                    <Route path="/AboutUs/Administration" exact element={<Administation />} />
                    <Route path="/News" exact element={<News />} />
                    <Route path="/News/ConsumerConfidenceReport" exact element={<ConsumerConfidenceReport />} />
                    <Route path="/News/BillingCalendar" exact element={<BillingCalendar />} />
                    <Route path="/News/Rates" exact element={<Rates />} />
                    <Route path="/News/Conservation" exact element={<Conservation />} />
                    <Route path="/News/WaterTreatment" exact element={<WaterTreatment />} />
                    <Route path="/News/WasteWaterTreatment" exact element={<WasteWaterTreatment />} />
                    <Route path="/News/Sewer" exact element={<Sewer />} />
                    <Route path="/AboutUs/Contact" exact element={<ContactUs />} />
                    <Route path="/Services" exact element={<Services />} />?
                    {/* <Route path="/Services/NewCustomerForm" exact element={<NewCustomerForm />} /> */}
                    {/* <Route path="/Services/TransferForm" exact element={<TransferForm />} /> */}
                    {/* <Route path="/Services/TerminationForm" exact element={<TerminationForm />} /> */}
                    <Route path="/Services/BankDraftForm" exact element={<BankDraftForm />} />
                    <Route path="/Services/MailChangeForm" exact element={<MailChangeForm />} />
                    <Route path="/Services/LeakForm" exact element={<LeakForm />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
          <Footer className="Footer" />
        </div>
      </Router>
    </AuthProvider >
  );
}

export default App;
