import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './ServicesSectionCSS.css';
import Axios from "axios";

const ServicesSection = () => {

  const [services, setServices] = useState([]);
  const fileName = 'HomepageServicesList.json';

  useEffect(() => {
    const loadData = async () => {
      Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
        const result = response.data;
        if (result.length !== 0) {
        setServices(result);
        }
        else {
          console.log("Services Empty")
        }
      })
    };
    try {
    loadData();
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div className='servicesSection'>
      <div className='serviceGrid' >
        {services.map((data) => {
          return (
            <Link
              to={"/Services/" + data.page}
              key={data.page}
              className='serviceBox'
              style={{ textDecoration: "none" }}
            >
              <div className='serviceNameContainer'>
                <Typography
                  className='serviceName text'
                  variant="h3"
                >
                  {data.name}
                </Typography>
              </div>

              <Typography
                className='serviceSubtext text'
                variant="h3"
              >
                {data.subtext}
              </Typography>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ServicesSection;
