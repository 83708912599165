import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Box, Button, Checkbox, Grid, Paper, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MuiTelInput } from 'mui-tel-input'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { add, isWeekend, parseISO } from 'date-fns';
import ReCAPTCHA from 'react-google-recaptcha';
import Axios from 'axios'
import { FormSX } from './FormSX.jsx';
import './FormCSS.css';

import PageHeader from '../../../Components/PageHeader/PageHeader.jsx';
import CardLinks from '../../../Components/CardLinks/CardLinks.jsx';

function BankDraftForm() {
  const header1 = 'Never Forget to Pay Your Water Bill Again'
  const paragraph1 = 'Set it and forget it!';
  const paragraph2 = 'Set up automatic payments today!';
  const linksFileName = 'Services.json';
  const urlDomain = 'Services';
  let today = add(new Date(), { days: 0 });

  const [recaptchaKey, setRecaptchaKey] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [sentData, setSentData] = useState({
    accountName: '',
    accountNumber: '',
    serviceAddress: '',
    startDate: '',
    emailAddress: '',
    phoneNumber: '',
    bankName: '',
    bankRouting: '',
    bankAccount: '',
    eSig: ''
  });

  const handleChange = (event) => {
    const { id, value } = event.target;
    setSentData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (errorMessage) {
      alert(errorMessage);
    }
  }, [errorMessage]);

  const submitForm = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a voided check image.');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      for (const key in sentData) {
        formData.append(key, sentData[key]);
      }

      const response = await Axios.post("https://searcywaterutilitiesservice.onrender.com/bankmail", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response.status === 200) {
        alert("Thank you for submitting your form.");
        window.location.reload();
      } else {
        const errorMessage = response.data?.message || "An error occurred while processing the request";
        setErrorMessage(errorMessage);
        console.error(response);
      }
    } catch (err) {
      console.error(err);
      if (err.message === 'Request failed with status code 409') {
        setErrorMessage("Cannot detect check in provided image. Please try again.")
      } else {
        setErrorMessage('An error occurred while submitting data.');
      }
    };
  }

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setErrorMessage(null);
  }

  const runRecaptcha = async () => {
    setSentData((prevData) => ({
      ...prevData,
      startDate: today,
    }));
    try {
      await Axios.get("https://searcywaterutilitiesservice.onrender.com/api/recaptcha").then((response) => {
        const result = response.data
        setRecaptchaKey(result)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    runRecaptcha()
  }, [])

  function reCaptcha() {
    setCaptchaVerified(!captchaVerified);
  }

  const { handleSubmit } = useForm();
  const onSubmit = async () => {
    if (!selectedFile) {
      console.log("No Voided Check")
      setErrorMessage("Image of voided check required.");
    } else {
      submitForm();
    }
  };

  return (
    <div>
      <PageHeader header1={header1} paragraph1={paragraph1} paragraph2={paragraph2} imgArr="bankDraftForm" alt='formPage' />
      <div className='ServiceForm'>
        <Paper elevation={10} sx={FormSX.paperStyle}>
          <form id="bankDraftForm" onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={FormSX.form}>
              <Typography variant="h1">Bank Draft Form</Typography>
              <hr style={FormSX.headerLine} />
              <Typography variant="p">Say goodbye to the stress of remembering due dates and late fees and hello to convenient and hassle-free automatic payments. Our automated system ensures that your water bill is paid on time, every time, effortlessly.</Typography>
              <Typography variant='p'>By signing up for automatic payments, you'll enjoy the peace of mind that comes with knowing your water bill will be taken care of without any manual intervention. Our secure and user-friendly form allows you to set up recurring payments directly from your bank account or credit card. With just a few simple clicks, you can establish a seamless payment schedule tailored to your preferences.</Typography>
              <Typography variant='p'>At Searcy Water Utilities, we prioritize customer satisfaction and strive to make your experience as smooth as possible. Our automatic payments form is designed to be intuitive and straightforward, eliminating any confusion or frustration. Take advantage of this time-saving solution today and join countless satisfied customers who have embraced the convenience of automatic payments. Simplify your life and ensure timely payments with Searcy Water Utilities' automated payment system.</Typography>
              <Box sx={{ display: 'flex' }} >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      id="accountName"
                      label="Customer Name"
                      placeholder="Enter Customer Name"
                      fullWidth
                      required
                      value={sentData.accountName}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{ flexShrink: 1, width: '100%' }}
                      id="accountNumber"
                      label="Water Account Number"
                      placeholder="Water Account Number"
                      value={sentData.accountNumber}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      id="serviceAddress"
                      label="Service Address"
                      placeholder="Enter Service Address"
                      required
                      value={sentData.serviceAddress}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider
                      sx={{ flexShrink: 1 }}
                      dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        required
                        openTo="day"
                        id="startDate"
                        shouldDisableDate={isWeekend}
                        label="Requested Date to Start"
                        value={sentData.startDate}
                        onChange={(event) => {
                          setSentData((prevData) => ({
                            ...prevData,
                            startDate: event,
                          }));
                        }}
                        renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                        minDate={parseISO(`${today}`)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      sx={{ width: '100%' }}
                      type='email'
                      id="emailAddress"
                      label="Email Address"
                      placeholder="Enter Email Address"
                      required
                      value={sentData.emailAddress}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MuiTelInput
                      sx={{ flexShrink: 1, width: '100%' }}
                      id="phoneNumber"
                      onlyCountries={'US'}
                      label="Phone Number"
                      value={sentData.phoneNumber}
                      onChange={
                        (event) => {
                          setSentData((prevData) => ({
                            ...prevData,
                            phoneNumber: event,
                          }));
                        }
                      }

                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="h4">Bank Information</Typography>
              <hr style={FormSX.headerLine} />
              <div className='formContainer'>
                <Grid container spacing={1}>
                  <Grid item sm={12} md={6} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="bankName"
                      label="Bank Name"
                      placeholder="Enter Bank Name"
                      required
                      value={sentData.bankName}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="bankRouting"
                      label="Routing Number"
                      placeholder="Enter Bank Routing Number"
                      required
                      value={sentData.bankRouting}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="bankAccount"
                      label="Bank Account Number"
                      placeholder="Enter Bank Account Name"
                      required
                      value={sentData.bankAccount}
                      onChange={(event) => handleChange(event)}
                    />
                  </Grid>
                  <Grid item md={6} sx={FormSX.input}>
                    <Button
                      id="lease"
                      variant="contained"
                      component="label"
                      required={true}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image of Voided Check*
                      <input
                        type="file"
                        hidden
                        onChange={onFileChange}
                      />
                    </Button>

                    <Typography variant='p'>{fileName}</Typography>
                  </Grid>
                </Grid>
              </div>
              <FormLabel>Please read these Bank Draft terms and conditions.</FormLabel>
              <Box sx={FormSX.SLA_Container}>
                <Box sx={FormSX.SLA_InnerContainer}>
                  <Typography variant='p'>It will not be necessary for Searcy Water Utilities or anyone employed by it to sign such drafts or checks, and I agree that your rights in respect to each draft or check shall be the same as if issued and signed personally by me. I agree that you shall be under no obligation to furnish me with any special advice or notice in writing or otherwise of the presentment or payment of any such draft or check or the charging of the same to my account. This authorization is to remain in effect until revoked by me in writing, and until you actually receive such notice, I agree that you shall be fully protected in honoring any such draft or check. I hereby authorize my bill at Searcy Water Utilities to be paid by my bank.</Typography>
                </Box>
              </Box>
              <FormControlLabel control={<Checkbox required={true} />}
                label="I have read and agree to the Bank Draft terms and conditions"
              />
              <TextField
                sx={{ marginBottom: '10px' }}
                id="eSig"
                label="E-Signature"
                placeholder="Sign Here"
                required
                value={sentData.eSig}
                onChange={(event) => handleChange(event)}
              />
              {(recaptchaKey !== undefined) ?
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={reCaptcha}
                />
                : null}
              <Button
                disabled={!captchaVerified}
                id="submit"
                variant="contained"
                style={{ marginTop: 10 }}
                type='submit' color='primary'
                fullWidth
              >Submit</Button>
            </FormControl>
          </form>
        </Paper>
      </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain} />
    </div>
  )
}


export default BankDraftForm