import React from 'react'
import error from '../Assets/images/404 Error.webp'

function PageNotFound() {
  return (
    <div style={{ margin: '50px 0', display: 'flex', justifyContent: 'center' }}>
      <img
      style={{ width: '100%' }}
      src={error}
      alt='404 error'
      />
    </div>
  )
}

export default PageNotFound