import React, { useEffect, useState } from 'react';
import './MessageBarCSS.css';

function MessageBar(data) {
    data = data.data
    const [holiday, setHoliday] = useState('');
    const [holidate, setHolidate] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [maintenance, setMaintenance] = useState(false);
    const [override, setOverride] = useState(false);
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            setHoliday(data[0].holiday)
            setMaintenance(data[1].maintenance)
            setOverride(data[2].override)
            let d = new Date(data[3].date)
            setHolidate(d.toDateString())
            setClosed(data[4].closed)
            setCustomMessage(data[5].message);
        }
    }, [data])

    return (
        <div id="messageBar">
            {(customMessage !== '') ?
                <div className='messageBar maintenance'>
                    <p>{customMessage}</p>
                </div>
                : null}
            {(maintenance || override) ?
                <div className='messageBar maintenance'>
                    <p>Payment Portal is Closed for Maintenance</p>
                </div>
                : null}
            {(closed) ?
                <div className='messageBar maintenance'>
                    <p>The Office is currently Closed. For Emergencies, please call: 501-268-2481</p>
                </div>
                : null}
            {(holiday !== "" && holiday !== "Christmas Eve") ?
                <div className='messageBar holiday'>
                    <p>Office will be closed on {holidate} for {holiday}</p>
                </div>
                : null}
            {(holiday === "Christmas Eve") ?
                <div className='messageBar holiday'>
                    <p>Office will be closed after noon on {holidate} for {holiday}</p>
                </div>
                : null}
        </div>
    )
}

export default MessageBar