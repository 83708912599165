export const FormSX = {
    paperStyle:{
        padding: '20px',
        height: 'auto',
        width: '60%',
        '@media (max-width: 780px)': {
            width: '80%'
          },
          '@media (max-width: 1080px)': {
            width: '70%'
          },
          '@media (max-width: 400px)': {
              width: '80%'
            },
        marginTop: '120px',
        marginBottom: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '10px',
    },
    form: {
        '& > *' : {
            color: '#1B2D72'
        }
    },
    FormLabel:{
        display: 'flex',
        color: '#1B2D72',
    },
    headerLine:{
        marginBottom: '15px',
        width: '300px'
    },
    ServiceGrid:{
        display: 'flex',
        justifyContent: 'start',
        padding: '0px 25px',
    },
    input:{ 
        width: '100%',
        marginTop: '5px'
    },
    SLA_Container:{ 
        maxHeight: '200px',
        margin: '15px',
        border: '1px solid #999',
        borderRadius: '5px'
    },
    SLA_InnerContainer:{
        maxHeight: '175px',
        overflowY: 'auto',
        margin: '12.5px 5px',
        '& span': {
            color: '#333 !important'
        }
    },
    messageContainer: { 
        /* width: '80%', */
        margin: '20px auto',
        padding: '15px',
        borderRadius: '10px',
        '& .messageSubject':{
            padding: '0',
            margin: '0',
            '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #000',
            },
        },
        },
        '& .messageText':{
            paddingLeft: '0',
            '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #000',
            },
        },
        }
    },

  };


  

