import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import GetPDF from '../../../Components/GetPDF/GetPDF';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import CardLinks from '../../../Components/CardLinks/CardLinks';

function Rates() {
    const header1 = 'Calculate Your Rates and Fees'
    const paragraph1 = 'No Surprises';
    const paragraph2 = 'Use this document to estimate your monthly rates and fees';
    const linksFileName = 'News.json';
    const urlDomain = 'News';

  const [recent, setRecent] = useState('');
  const fileName = 'Rates.json';


  useEffect(() => {
    const loadData = async () => {
      try {
        await Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
          const result = response.data;
          const reports = result[result.length - 1];
          setRecent(reports.file);
        })
      } catch (err) {
        console.log(err)
      }
    };
    loadData()
  }, []);

  return (
    <>
    <PageHeader header1={header1} paragraph1={paragraph1} paragraph2={paragraph2} imgArr="rates" alt='formPage' />
    <div style={{ marginTop: '100px' }}>
      <GetPDF fileName='Rates.json' controls={true} size={1000} page={1} recent={recent}/>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </div>
    </>
  )
}

export default Rates