export const TipCardSX = {
    card: { 
        width: '500px',
        height: '610px',
        position: 'relative',
        '@media (max-width: 1380px)': {
            height: 'auto'
        }
    },
    smMobileCard: { 
        width: '300px',
    },
    mobileCard: { 
        width: '350px',
    },
    smTabletCard: { 
        width: '450px',
    },
    tabletCard: { 
        width: '600px',
    },
    img: { 
        width: '500px', 
        height:'368px',
        objectFit: 'cover', 
    },
    smMobileImg: { 
        width: '400px',
        height:'200px',
        objectFit: 'cover', 
    },
    mobileImg: { 
        width: '400px',
        height:'300px',
        objectFit: 'cover', 
    },
    smTabletImg: { 
        width: '450px',
        height:'300px',
        objectFit: 'cover', 
    },
    tabletImg: { 
        width: '600px',
        height:'400px',
        objectFit: 'cover', 
    },
} 