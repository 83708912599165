import React from 'react'
import CardLinks from '../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import StyledBlock from '../../../Components/StyledBlocks/StyledBlock';

function WaterTreatment() {
  const header1 = 'Water Treatment Plant'
  const paragraph1 = 'How we treat the water you drink.';
  const linksFileName = 'News.json';
  const styledBlockFileName = 'StyledBlocks/WaterTreatment.json';
  const urlDomain = 'News';

  

  return (
    <div className='newsPage'>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="waterTreatment" alt='subPage' />
      <div className='subPageContainer'>
      <StyledBlock fileName={styledBlockFileName} selectionList="WaterTreatmentSteps"/>
      </div>
      
      <div className='aboutUs'>
        <CardLinks urlDomain={urlDomain} fileName={linksFileName}/>
      </div>
    </div>
  )
}

export default WaterTreatment