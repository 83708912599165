import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './CardLinksCSS.css'
import PropTypes from 'prop-types';

const CardLinks = (props) => {
  const [location, setLocation] = useState('');
  const [linkText, setLinkText] = useState('Learn More About Us');

  const navigate = useNavigate();

  useEffect(() => {
    const setNewLocation = (newLocation) =>{
      setLocation(newLocation);
    }
    
    const path = window.location.pathname;
    const secondSlashIndex = path.indexOf('/', path.indexOf('/') + 1);
    const extractedText = path.substring(0, secondSlashIndex >= 0 ? secondSlashIndex : path.length);
    
    setLocation(extractedText);
    setNewLocation(extractedText);

    const text = location === '/Services' ? 'Our Available Online Services' : location === '/News' ? 'Learn More About Our Processes' : location === '/AboutUs' ? 'Learn More About Us' : '';
    setLinkText(text)
  },[location, navigate])


    useEffect(() => {const loadData = async () => {
        Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { 'fileName': props.fileName }).then((response) => {
          const result = response.data;
          setCards(result);
        })
      };
        loadData();
      }, [props.fileName])
    
      const [cards, setCards] = useState([]);
    
      
  return (
    <>
      <h2 id='links' className='cardLinksSectionTitle'>
        {linkText}
        </h2>

    <div className='pageGrid'>
      {cards.map((card, key) => {
          return (
            <div
            key={key}
            className='pageColumn'
            >
              {props.urlDomain !== undefined ? (
                <div 
                  className='elementAccent'>
                <Link to={`/${props.urlDomain}/` + card.page}>
                    <h3 className='header fullHeader'>
                    {card.header}
                    </h3>
                    <p className='subHeader fullHeader'>{card.subHeader}</p>
                <div 
                  className='pageCard'>
                  <img
                  loading='lazy'
                  src={`https://searcywaterutilitiesservice.onrender.com/images/CardLinks/${card.img}`}
                  alt={card.alt}
                  style={{ objectPosition: `${card.offsetX} ${card.offsetY}` }}
                  />
                  <div className='pageCard-Text'>
                  <h3 className='header halfHeader'>
                    {card.header}
                    </h3>
                    <p className='subHeader halfHeader'>{card.subHeader}</p>
                    <div>
                    {card.subtext.map((subtext, key) => {
                      return (
                      <p key={key}>{subtext}</p>
                      )})}
                      </div>
                      </div>
                </div>
                </Link>
                </div>
              ):(
              <div 
                className='elementAccent noLink'>
                    <h3 className='header fullHeader'>
                    {card.header}
                    </h3>
                    <p className='subHeader fullHeader'>{card.subHeader}</p>
                    <div 
                  className='pageCard noLink'>
                <img
                className='noLink'
                loading='lazy'
                src={`https://searcywaterutilitiesservice.onrender.com/images/CardLinks/${card.img}`}
                alt={card.alt}
                style={{ objectPosition: `${card.offsetX} ${card.offsetY}` }}
                />
                <div className='pageCard-Text'>
                <h3 className='header halfHeader'>
                  {card.header}
                  </h3>
                  <p className='subHeader halfHeader'>{card.subHeader}</p>
                  <div>
                  {card.about.map((subtext, key) => {
                    return (
                    <p key={key}>{subtext}</p>
                    )})}
                    </div>
                    </div>
                    </div>
              </div>
              )}
              
            </div>
          )
        })}
    </div>
    </>
  )
}

CardLinks.propTypes = {
    fileName: PropTypes.string.isRequired,
    urlDomain: PropTypes.string,
    }
export default CardLinks