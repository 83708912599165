export const FooterSX = {
  divider: {
    backgroundColor: "white",
    width: "1px",
    height: "inherit",
    margin: "0 45px",
  },
  mapBox: {
    height: "100%",
    marginTop: "-24px",
    marginLeft: "-30px",
  }
}