import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import './NewsCSS.css'
import PageHeader from '../../Components/PageHeader/PageHeader';
import GetPDF from '../../Components/GetPDF/GetPDF';
import CardLinks from '../../Components/CardLinks/CardLinks';
import { Link } from 'react-router-dom';
import Jellyfish from '../../Assets/images/NYKAYH2O/pexels-229fghjdfg1.png'
import StyledBlock from '../../Components/StyledBlocks/StyledBlock';
import NYKAYH2O from '../../Components/NYKAYH2O/NYKAYH2O';

const News = () => {
  const fileName = 'BillingCalendar.json';
  const [recent, setRecent] = useState('');
  const [size, setSize] = useState(800);
  const newDate = new Date();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  useEffect(() => {
    onResize();
  
    function onResize() { 
      if (window.innerWidth >= 900) {
        if (size !== 800) {
          setSize(800);
        }
      } else if (window.innerWidth >= 750) {
        if (size !== 600) {
          setSize(600);
        }
      } else if (window.innerWidth >= 650) {
        if (size !== 425) {
          setSize(425);
        }
      } else if (window.innerWidth >= 550) {
        if (size !== 350) {
          setSize(350);
        }
      } else if (window.innerWidth >= 400) {
        if (size !== 300) {
          setSize(300);
        }
      } else {
        setSize(300);
      }
    }
  
    window.addEventListener('resize', onResize);
  
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [size]);

  const header1 = 'Stay Informed!';
  const paragraph1 = 'See our latest updates and changes, while you\'re here, discover effective ways to conserve water!';
  const NYKtitle = 'Average Cost Savings'
  const NYKparagraph = 'According to the Environmental  Protection Agency (EPA), the average American Household can save approximately $170 per year on their water bill by making simple water-saving changes like the ones stated below'
  const linksFileName = 'News.json';
  const urlDomain = 'News';
  const styledBlockFileName1 = 'StyledBlocks/News/News1.json';
  const styledBlockFileName2 = 'StyledBlocks/News/News2.json';
  
  useEffect(() => {
    const loadData = async () => {
      try {
        await Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
          const result = response.data;
          const reports = result[result.length - 1];
          setRecent(reports.file);
        })
      } catch (err) {
        console.log(err)
      }
    };
    loadData()
  }, []);
  return (
    <div className='newsPage'>
        <PageHeader header1={header1} paragraph1={paragraph1} imgArr="news" />
        
    <div className='centeredInElement'>
        <div className='calendar'>
            <h2 className='centeredInElement' >{year} Billing Calendar</h2>
            <Link className='hiddenButton btn' to='/News/BillingCalendar'>Open</Link>
              <GetPDF fileName='BillingCalendar.json' controls={false} size={size} page={month} recent={recent}/>
        </div>
    </div>
            <div className='subPageContainer'>
                <StyledBlock fileName={styledBlockFileName1}/>
                <NYKAYH2O id='AverageCostSavings' direction={true} title={NYKtitle} img={Jellyfish} alt="Jellyfish" paragraph={NYKparagraph}/>
                <StyledBlock fileName={styledBlockFileName2}/>
            </div>
            
        <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </div>
  )
}

export default News
