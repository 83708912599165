import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import './PageHeaderCSS.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

const PageHeader = (props) => {
    const [imageNum, setImageNum] = useState(0);
    const [images, setImages] = useState([]);
    const theme = useTheme();
    const laptop = useMediaQuery(theme.breakpoints.up('899'));
    const loading = (
    <div className='pageImg' >
        <Loader />
    </div>
    )
    
    useEffect(() => {
      const loadImages = async () => {
        Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
          const result = response.data[props.imgArr];
          setImages(result);
        })
      };
      loadImages();
    }, [props.imgArr])

    const fileName = 'PageHeaderImages.json';

    
  setTimeout(() => {
    setImageNum(imageNum + 1);
    if (imageNum >= images.length - 1){
      setImageNum(0);
    }
  }, 5000);

  return (
    <div>
      <div className={`pageHeader ${props.alt ? `${props.alt}` : ''}`}>
        <div className='pageHeaderImg'>
            {images[imageNum] === undefined ? loading : 
         <img 
         className={`pageImg ${props.alt ? `${props.alt}` : ''}`}
         src={`https://searcywaterutilitiesservice.onrender.com/images/${images[imageNum].img}`}
         alt={images[imageNum].alt}
        />}
        <div className={`backgroundDots ${props.alt ? `${props.alt}` : ''}`}/>
        </div>
        <div className='pageHeaderText'>
        <h1 style={{ ...laptop ? {marginTop: `${props.offsetX}px`}: '' }}>{props.header1}</h1>
        <h1>{props.header2}</h1>
        <p className={`${props.alt ? `${props.alt}` : ''}`}>{props.paragraph1}</p>
        {props.paragraph2 !== undefined && props.paragraph !== '' ? (
        <p className={`${props.alt ? `${props.alt}` : ''}`}>{props.paragraph2}</p>
        ): ''}
        </div>
      </div>
    </div>
  )
}

PageHeader.propTypes = {
    header1: PropTypes.string,
    header2: PropTypes.string,
    paragraph1: PropTypes.string,
    paragraph2: PropTypes.string,
    alt: PropTypes.string,
    imgArr: PropTypes.string,
    }
export default PageHeader
