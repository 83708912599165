import React from 'react'
import NYKAYH2O from '../../../../Components/NYKAYH2O/NYKAYH2O'
import PageHeader from '../../../../Components/PageHeader/PageHeader'
import './ConservationCSS.css'
import HardhatMan from '../../../../Assets/images/NYKAYH2O/pexels-2291_20.png'
import WaterDrop from '../../../../Assets/images/NYKAYH2O/WaterConservation Drop.png'
import { FaDollarSign, FaHammer, FaSeedling,  } from 'react-icons/fa'
import { MdWaterDrop } from 'react-icons/md'
import CardLinks from '../../../../Components/CardLinks/CardLinks'
import StyledBlock from '../../../../Components/StyledBlocks/StyledBlock'

const Conservation = () => {
    const header1 = 'Every Drop Counts'
    const paragraph1 = 'Let\'s do our part to conserve water and create a healthier, more sustainable world for future generations'
    
    const NYKtitle1 = 'Water is a Finite Resource'
    const NYKparagraph1 = 'As our population grows, it becomes increasingly important to use water wisely and efficiently. By conserving water, we can help ensure that there is enough water to meet the needs of future generations'
    
    const linksFileName = 'News.json';
    const urlDomain = 'News';
    const styledBlockFileName1 = 'StyledBlocks/Conservation/Conservation.json';
    const styledBlockFileName2 = 'StyledBlocks/Conservation/Conservation2.json';

  return (
    <div className='newsPage waterConservationPage'>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="conservation" alt='subPage' />
      <div className='subPageContainer'>
        <StyledBlock fileName={styledBlockFileName1}/>
        <NYKAYH2O id='WaterFinite' direction={false} title={NYKtitle1} titleOffset={'-215px'} img={HardhatMan} alt={"Man in a hardhat"} paragraph={NYKparagraph1}/>
        <div className='waterPreservation'>
          <div className='preservationTip'>
            <img
            src={WaterDrop}
            alt='Preservation tip'
            />
          </div>
        <div className='preservationBenefits-cards'>
          <div className='PB-Cards PB-Cards-TL'>
            <h3>Lower Your Water Bill</h3>
            <p>By conserving water, you reduce how much you use and by extension, how much to pay for water services.</p>
            <div className='PB-Cards-Icon-Div'>
              <FaDollarSign className='PB-Cards-Icon'/>
            </div>
          </div>
          <div className='PB-Cards PB-Cards-TR'>
            <h3>Extend the Lifetime of Water Infrastructure</h3>
            <p>Each time you use water, the pipes in your home and neighborhood are under stress. They need continued maintenance to ensure the health and safety of the infrastructure.</p>
            <div className='PB-Cards-Icon-Div'>
              <FaHammer className='PB-Cards-Icon'/>
            </div>
          </div>
          <div className='PB-Cards PB-Cards-BL lg-screen'>
            <div className='PB-Cards-Icon-Div'>
              <FaSeedling className='PB-Cards-Icon'/>
              </div>
            <h3>Reduce Your Environmental Impact</h3>
            <p>By using less water, we can conserve the water that is naturally in the environment while reducing our carbon footprint.</p>
          </div>
          <div className='PB-Cards PB-Cards-BR'>
            <div className='PB-Cards-Icon-Div'>
              <MdWaterDrop className='PB-Cards-Icon'/>
              </div>
            <h3>Improve Water Pressure</h3>
            <p>Water pressure can drop during times of high demand. Conserving water can help maintain the pressure in your home.</p>
          </div>
          <div className='PB-Cards PB-Cards-BL sm-screen'>
            <div className='PB-Cards-Icon-Div'>
              <FaSeedling className='PB-Cards-Icon'/>
              </div>
            <h3>Reduce Your Environmental Impact</h3>
            <p>By using less water, we can conserve the water that is naturally in the environment while reducing our carbon footprint.</p>
          </div>
        </div>
        </div>
        <StyledBlock fileName={styledBlockFileName2} selectionList="ConservationTips"/>
      </div>
      
        <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </div>
  )
}

export default Conservation
