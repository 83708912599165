import '../../App.css';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../Components/Auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { Avatar, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function AdminPortal() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [username, setUsername] = useState("");
    const [userId, setUserId] = useState("");
    const [access, setAccess] = useState(false);
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [show, setShow] = useState('none');
    const [hide, setHide] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const redirectPath = location.state?.path || '/AdminForms';

    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 280,
        margin: '20px auto',
        borderRadius: '10px'
    }

    const errorMessage = document.getElementById("errorMessage");

    const auth = useAuth();

    const loginUser = () => {
        Axios.post("https://searcywaterutilitiesservice.onrender.com/login", { username, password }).then((response) => {
            const userInfo = response.data;                              
            setUserId(userInfo);

            if (!response.data) {
                errorMessage.innerHTML = "Username or Password is wrong";
            } else {
                setAccess(true);
                formChange();
            };
        })
    };

    const formChange = () => {
        setHide('none');
        setShow('');
        document.getElementById("submit").innerHTML = "Re-Send Verification";
    }

    const verifyCode = () => {
        if (!access) {
            console.log("Attempted Unauthorized Entry");
        } else {
            Axios.post("https://searcywaterutilitiesservice.onrender.com/verifyOTP", { verificationCode, userId }).then((response) => {
                console.log(response.data);
                if (!response.data) {
                    errorMessage.innerHTML = "Verification Code Incorrect."
                } else {
                    errorMessage.innerHTML = "Verification Code Correct."
                    auth.login(username);
                    navigate(redirectPath, { replace: true });
                }
            })
        }
    }


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        errorMessage.innerHTML = "";
        loginUser();
    }

    return (
        <div style={{ marginTop: "150px", marginBottom: "50px" }}>
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align='center'>
                        <Avatar style={{ backgroundColor: 'rgba(20,113,61,1)' }}><LockOutlinedIcon /></Avatar>
                        <Typography variant="inika">Admin Portal Sign In</Typography>
                    </Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField id="username"
                            sx={{ display: hide }}
                            
                            variant="standard"
                            label='Username'
                            placeholder='Enter Username'
                            fullWidth autoFocus
                            {...register("username", { required: "Required Field" })}
                            error={!!errors?.username}
                            helperText={errors?.username ? errors.username.message : null}
                            onChange={(e) => { setUsername(e.target.value) }}
                        />
                        <TextField
                            sx={{ display: hide }}
                            
                            id="password"
                            variant="standard"
                            label='Password'
                            placeholder='Enter Password'
                            type='password'
                            fullWidth
                            {...register("password", { required: "Required Field" })}
                            error={!!errors?.password}
                            helperText={errors?.password ? errors.password.message : null}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                        <TextField
                            sx={{ display: show }}
                            autoComplete=''
                            id="verificationCode"
                            variant="standard"
                            label='Verification Code'
                            placeholder='Enter Verification Code'
                            fullWidth
                            onChange={(e) => { setVerificationCode(e.target.value) }}
                        />
                        <p style={{ marginTop: 10 }} id="errorMessage"></p>
                        <Button
                            sx={{ display: show }}
                            id="verify"
                            variant="contained"
                            style={{ marginTop: 10 }}
                            color='primary'
                            onClick={verifyCode}
                            fullWidth>Verify</Button>
                        <Button
                            id="submit"
                            variant="contained"
                            style={{ marginTop: 10 }}
                            type='submit' color='primary'
                            fullWidth>Sign In</Button>
                    </form>
                </Paper>
            </Grid>
        </div>
    )
};

export default AdminPortal