import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Checkbox, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MuiTelInput } from 'mui-tel-input'
import { format, isWeekend, parseISO } from 'date-fns';
import ReCAPTCHA from "react-google-recaptcha";
import Axios from 'axios'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { FormSX } from './FormSX.jsx';
import CardLinks from '../../../Components/CardLinks/CardLinks.jsx';
import PageHeader from '../../../Components/PageHeader/PageHeader.jsx';


function MailChangeForm() {
  const [recaptchaKey, setRecaptchaKey] = useState();
  const header1 = 'Billing and Service Address are Different?'
  const paragraph1 = 'Use this form to separate your billing address from your service address';
  const linksFileName = 'Services.json';
  const urlDomain = 'Services';

  let today = format(new Date(), 'yyyy-MM-dd');
  const [accountName, setAccountName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [dlState, setDLState] = useState('');
  const [dlNumber, setDLNumber] = useState('');
  const [serviceAddress, setServiceAddress] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [newMailingAddress, setNewMailingAddress] = useState('');
  const [startDate, setStartDate] = useState(today + 'T08:00');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailInvoice, setEmailInvoice] = useState(true);
  const [eSig, setESig] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const [sentData, setSentData] = useState([]);

  const selectState = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ]

  useEffect(() => {
    setSentData(JSON.parse(
      `{
        "accountName" : "${accountName}",
        "dlState" : "${dlState}",
        "dlNumber" : "${dlNumber}",
        "accountNumber" : "${accountNumber}",
        "serviceAddress" : "${serviceAddress}",
        "newMailingAddress" : "${newMailingAddress}",
        "startDate" : "${startDate}",
        "emailAddress" : "${emailAddress}",
        "emailInvoice" : "${emailInvoice}",
        "phoneNumber" : "${phoneNumber}",
        "eSig" : "${eSig}"
        }`)
    )
  }, [
    accountName,
    dlState,
    dlNumber,
    accountNumber,
    serviceAddress,
    startDate,
    emailAddress,
    emailInvoice,
    phoneNumber,
    newMailingAddress,
    eSig
  ]);

  const runRecaptcha = async () => {
    try {
      await Axios.get("https://searcywaterutilitiesservice.onrender.com/api/recaptcha").then((response) => {
        const result = response.data
        setRecaptchaKey(result)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    runRecaptcha()
  }, [])

  const dlStateChange = (e) => {
    setDLState(e.target.value);
  }

  const dlNumberChange = (e) => {
    setDLNumber(e.target.value)
  }

  const phoneChange = (newValue) => {
    setPhoneNumber(newValue);
  }

  const accountNumberChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setAccountNumber(e.target.value)
    }
  }

  function reCaptcha() {
    setCaptchaVerified(!captchaVerified);
  }

  const sendData = async () => {
    Axios.post("https://searcywaterutilitiesservice.onrender.com/transfermail", { sentData }).then((response) => {
      if (response.status === 200) {
        alert("Thank you for submitting your form, we have received it and will get back to you soon!")
        window.location.reload()
      } else {
        alert("There was an error trying to send your form, please try again later or call our office")
      }
    })
  }

  const { handleSubmit } = useForm();
  const onSubmit = () => {
    if (phoneNumber.length < 7) {
      alert("Phone number is required!")
    }
    else {
    sendData();
    }
  }

  return (
    <div>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="movingForm" alt='formPage' />
      <div className='ServiceForm'>
        <Paper elevation={10} sx={FormSX.paperStyle}>
          <form id="mailChangeForm" onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <Typography variant="h1">Mailing Address Change Form</Typography>
              <hr style={FormSX.headerLine} />
              <Typography variant="p">Managing billing and financial information can be complex, especially when the billing address differs from the service address. Searcy Water Utilities is here to assist you with our dedicated Billing Address Change Form. Whether you are the property owner responsible for paying the water bill on behalf of tenants or you need to update your billing address due to differing residential and mailing addresses, our form is tailored to your specific needs.</Typography>
              <Typography variant='p'>By utilizing our streamlined process, you can easily update your billing address while keeping your service address unchanged. This ensures that your water service remains uninterrupted, and that you receive accurate and timely bills at the appropriate address. Simply provide us with the necessary information, including your current service address, the new billing address, and your contact details, to initiate the address change efficiently.</Typography>
              <Typography variant='p'>At Searcy Water Utilities, we strive to provide exceptional customer service. Our user-friendly Billing Address Change Form ensures that your billing information is always up to date, no matter the unique circumstances. Take advantage of this convenient solution and maintain accurate billing records for your property today.</Typography>
              <Typography variant="p" sx={{ marginBottom: '15px' }}>*This form is only for current Searcy Water Utilities customers</Typography>
              <div className='formContainer'>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={8} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="accountName"
                      label="Customer Name"
                      placeholder="Enter Customer Name"
                      required
                      onChange={(e) => { setAccountName(e.target.value) }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={FormSX.input}>
                    <MuiTelInput
                      required
                      sx={{ width: '100%' }}
                      disableDropdown
                      onlyCountries={'US'}
                      label="Phone Number"
                      onChange={phoneChange}
                      value={phoneNumber} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="DLNumber"
                      value={dlNumber}
                      label="Driver's License Number"
                      placeholder="Enter Driver's License Number"
                      required
                      onChange={(e) => { dlNumberChange(e) }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={FormSX.input}>
                    <FormControl
                      style={{ width: '100%', marginRight: '5px' }}>
                      <InputLabel id='stateLabel'>DL State</InputLabel>
                      <Select
                        required
                        labelId="DLStateLabel"
                        id="DLState"
                        value={dlState}
                        label="DL State"
                        onChange={dlStateChange} >
                        {selectState.map((data) => {
                          return (
                            <MenuItem key={data} value={data}>{data}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="serviceAddress"
                      label="Current Service Address"
                      placeholder="Enter Current Service Address"
                      required
                      onChange={(e) => { setServiceAddress(e.target.value) }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="accountNumber"
                      value={accountNumber}
                      label="Water Account Number"
                      placeholder="Water Account Number"
                      onChange={(e) => { accountNumberChange(e) }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="newServiceAddress"
                      label="New Mailing Address"
                      placeholder="Enter New Mailing Address (Street, City, State, Zip)"
                      required
                      onChange={(e) => { setNewMailingAddress(e.target.value) }}
                    /></Grid>
                  <Grid item xs={12} sm={6} md={4} sx={FormSX.input}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}>
                      <MobileDateTimePicker
                        disableIgnoringDatePartForTimeValidation={false}
                        required
                        openTo="day"
                        shouldDisableDate={isWeekend}
                        label="Requested Date to Change"
                        value={startDate}
                        onChange={(e) => {
                          console.log(e)
                          setStartDate(e)
                        }}
                        renderInput={(props) => <TextField {...props} fullWidth />}
                        minDateTime={parseISO(`${today}T08:00`)}
                        maxTime={parseISO(`${today}T16:00`)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      type='email'
                      id="email"
                      label="Email Address"
                      placeholder="Enter Email Address"
                      required
                      onChange={(e) => { setEmailAddress(e.target.value) }}
                    />
                  </Grid>
                </Grid>
              </div>
              <FormControlLabel control={<Checkbox
                required
                defaultChecked
                value={emailInvoice}
                onChange={() => (setEmailInvoice(!emailInvoice))}
              />}
                label="Receive invoices via e-mail?"
              />
              <TextField
                sx={{ marginBottom: '10px' }}
                id="signature"
                label="E-Signature"
                placeholder="Sign Here"
                required
                onChange={(e) => { setESig(e.target.value) }}
              />
             {(recaptchaKey !== undefined) ?
                    <ReCAPTCHA
                      sitekey={recaptchaKey}
                      onChange={reCaptcha}
                    />
                  : null }
              <Button
                disabled={!captchaVerified}
                id="submit"
                variant="contained"
                style={{ marginTop: 10 }}
                type='submit' color='primary'
                fullWidth
              >Submit</Button>
            </FormControl>
          </form>
        </Paper>
      </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain} />
    </div>
  )
}

export default MailChangeForm