import React from "react";
import "./DiamondLineCSS.css";
import SquareIcon from "@mui/icons-material/Square";

const DiamondLine = (props) => {
  return (
    <>
{props.reverse ? (
  /* Section Title - LEFT - */
  <div className='diamondLineDiv'>

  {/* Diamond Line */}
    <div className='diamondLine right'>
      <SquareIcon className='squareIcon left'/>
    </div>
    <div className='sectionTitle'>
      <h2>{props.title}</h2>
    </div>
  </div>


):(
  /* Section Title - RIGHT - */
  <div className='diamondLineDiv'>
    
    {/* Diamond Line */}
    <div className='diamondLine left'>
      <SquareIcon className='squareIcon' />
    </div>
    <div className='sectionTitle right'>
      <h2>{props.title}</h2>
    </div>
  </div>
        
)}
    </>
  );
};

export default DiamondLine;