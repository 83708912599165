import React from "react";
import FollowPage from "./FollowUs/FollowUs"
import "slick-carousel/slick/slick.css"
import 'slick-carousel/slick/slick-theme.css'
import ServicesSection from './ServicesSection/ServicesSection';
import ConservationTips from './HomepageConservation/HomepageConservation';
import vid2 from '../../Assets/videos/Zero Period sm 2.mp4'
import './HomepageCSS.css';


const Homepage = () => {

  return (
    <>
      <div className='motto'>
        <video autoPlay loop muted >
        <source src={vid2} type="video/mp4"/>
        </video>
      </div>
        <ServicesSection />
        <ConservationTips />
        <FollowPage />
      </>
  )
}

export default Homepage
