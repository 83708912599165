 import React, { useState } from "react";
 import Slider from "react-slick";
 import "./slick.css";
 import "./slick-theme.css";
 import NavigateNextIcon from "@mui/icons-material/NavigateNext";
 import { Typography } from "@mui/material";
 import TipCard from "./TipCard/TipCard"
 import { useTheme } from '@mui/material/styles';
 import useMediaQuery from '@mui/material/useMediaQuery';

const CustomSlider = (props) => {
  const theme = useTheme();
  const singleCard = useMediaQuery(theme.breakpoints.down('1380'));

    const [slider, setSlider] = useState({});

      /* Slider Control Functions */
   const next = () => {
    slider.slickNext();
  }
  const previous = () => {
    slider.slickPrev();
  }

  /* Slider Content */
  const sliders = () => {
    return props.src.map((data,key) => {
      return (
        <div key={key}>
          {props.type === 'banner' ? (
        <div style={{ position: "relative"}}>
          <img
            className={props.type + "Img"}
            alt={data.name}
            src={`https://searcywaterutilitiesservice.onrender.com/images/${data.image}`}
            loading="lazy"
          />
          <Typography variant="h3" className={props.type + "SubText"}>
            {data.subText}
          </Typography>
        </div>
        ): (
          <div className='tipCardDiv'>
            <div className='tipCardCentering'>
              <TipCard data={data}/>
            </div>
        </div>
        )}
        </div>
      );
    });
  }

    const settings = {
      autoplay: true,
      autoplaySpeed: props.type === 'banner' ? 5000 : 10000,
      dots: props.type === 'banner' ? false : true,
      infinite: true,
      speed: 1000,
      fade: props.fade,
      slidesToShow: props.type === 'banner' ? 1 : singleCard ? 1 : 2,
      slidesToScroll: 1,
    };
    return (
        <div style={{ position: "relative", height: props.height + "px", }}>
          <Slider ref={(c) => (setSlider(c))} {...settings}>
          {sliders()}
          </Slider>
          <div className={props.type === 'tip' ? 'tipBtnContainer': ''}>
          <div
            className={props.type === 'tip' ? 'carouselBtns nextBtn tip': 'carouselBtns nextBtn'}
            onClick={() => {
              next();
            }}
          >
            <NavigateNextIcon />
          </div>
          <div
            className={props.type === 'tip' ? 'carouselBtns prevBtn tip': 'carouselBtns prevBtn'}
            onClick={() => {
              previous();
            }}
          >
            <NavigateNextIcon />
          </div>
          </div>
        </div>
      );
}

export default CustomSlider