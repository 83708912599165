import { Divider, Grid } from "@mui/material";
import { FooterSX } from "./FooterSX"
import { Link } from "react-router-dom";
import "./FooterCSS.css";
import PipeHead from "../../Assets/images/Pipes/pipeHead2.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {

const backflowPDF = '/pdf/Backflow-Test-Form.pdf'
const jobApp = '/pdf/SWUApplication.pdf'

  const theme = useTheme();
  const laptop = useMediaQuery(theme.breakpoints.down('1200'));
  const tablet = useMediaQuery(theme.breakpoints.down('900'));
  return (
    <>
      <div className='footer'>
        <div className='footerForeground'>
          <Grid
            container
            spacing={3}
            style={{ display: "flex", justifyContent: "center", ...tablet ? { justifyContent: "start" } : '', }}
          >
            <Grid item className='footerInfo' xs={12} sm={6} md={6} lg={3} >
              <ul className='hours'>
                <li>Hours of Operation</li>
                <li>Office: Monday - Friday</li>
                <li>8:00am - 4:30pm</li>
              </ul>
              <ul className='hours'>
                <li>After Hours Emergencies</li>
                <li>Please call: <a href='tel: (501) 268-2481'>(501) 268-2481</a></li>
              </ul>
            </Grid>

            <Divider sx={{ ...FooterSX.divider, ...laptop ? { display: 'none' } : '' }} variant="middle" />
            <Grid item className='footerInfo' xs={12} sm={6} md={6} lg={2}>
              <ul>
                <li><Link to="/Services">Services</Link></li>
                <li><Link to="/Services/BankDraftForm">Automatic Payments</Link></li>
                <li><Link to="/Services/LeakForm">Report Leak</Link></li>
                <li><Link to="/Services/MailChangeForm">Change Mailing Address</Link></li>
                {/* <li><Link to="/Services/NewCustomerForm">New Service</Link></li> */}
                {/* <li><Link to="/Services/TransferForm">Transfer Service</Link></li> */}
                {/* <li><Link to="/Services/TerminationForm">End Service</Link></li> */}
                <li><Link to="/adminportal">Admin Portal</Link></li>
              </ul>
            </Grid>
            <Grid item className='footerInfo' xs={12} sm={6} md={6} lg={2}>
              <ul>
                <li><Link to="/News">News & Info</Link></li>
                <li><Link to="/News/BillingCalendar">Billing Schedule</Link></li>
                <li><Link to="/News/ConsumerConfidenceReport">Water Quality Report</Link></li>
                <li><a href="https://www.ark.org/health/eng/autoupdates/xcon/xcon73.htm" target="blank">Backflow Testers</a></li>
                <li><a href={backflowPDF}>Backflow Test Form</a></li>
              </ul>
            </Grid>
            <Grid item className='footerInfo' xs={12} sm={6} md={6} lg={2}>
              <ul>
                <li><Link to="/AboutUs">About Us</Link></li>
                <li><Link to="AboutUs/History">History</Link></li>
                <li><Link to="AboutUs/Board">Board of Public Utilities</Link></li>
                <li><Link to="AboutUs/Administration">Administration</Link></li>                
                <li><a href={jobApp}>Download Job Application</a></li>                
                <li><Link to="AboutUs/Contact">Contact Us</Link></li>
              </ul>
            </Grid>
          </Grid>
        </div>

      </div>
      <div className='copyright'>
        <p>Copyright &copy; 2023 </p>
        <p>Searcy Water Utilities</p>
        <p>All rights reserved</p>

      </div>
      <div className='pipe' style={{ position: 'relative' }}>
        <div className='head' style={{ position: 'absolute', bottom: '52px' }}>
          <img src={PipeHead} alt="Pipe Head" style={{ height: '52px', position: 'absolute', }} />
        </div>
        <div className='head' style={{ position: 'absolute', bottom: '52px', right: '28px' }}>
          <img src={PipeHead} alt="Pipe Head" style={{ height: '52px', position: 'absolute', transform: 'rotate(180deg)' }} />
        </div>
      </div>
    </>
  );
};

export default Footer;
