import React, { useState, useEffect } from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import HistoryItem from './HistoryItem/HistoryItem.js';
import Axios from 'axios';
import { Typography } from '@mui/material';
import PageHeader from '../../../Components/PageHeader/PageHeader.jsx';
import CardLinks from '../../../Components/CardLinks/CardLinks.jsx';

const History = () => {
  const header1 = 'The History of Searcy Water Utilities'
  const paragraph1 = 'Operating Since 1896';
  const paragraph2 = 'Timeline';
  const linksFileName = 'AboutUs.json';
  const urlDomain = 'AboutUs';

  const [history, setHistory] = useState([]);
  const fileName = 'HistoryList.json';
  
  useEffect(() => {
    loadData();
    window.scrollTo(0, 0)
  }, [])


  const loadData = async () => {
    Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
      const result = response.data;
      setHistory(result);
    })
  };

  return (
    <>
    <PageHeader header1={header1} paragraph1={paragraph1} paragraph2={paragraph2} imgArr="history" alt='subPage' />
    <div style={{ marginTop: "100px", minHeight: '100vh' }} className="History">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant='h2' sx={{ padding: '0px 30px', textAlign: 'center' }}>
        History of Searcy Water Utilities
        </Typography>                        
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <hr style={{ width: '50%', background: '#1B2D72', height: '2px', border: 'none' }} />
        </div>        
      <VerticalTimeline lineColor="#1B2D72">
        {history.map((data, idx) => {
          return (                  
          <HistoryItem
            id={idx} key={idx} name={data.title}
            subText={data.texts[0][0].text}
            date={data.img_text} icon={data.icon} />
          )
        })}
      </VerticalTimeline>
    </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </>
  )
}

export default History