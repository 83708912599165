import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import GetPDF from '../../../Components/GetPDF/GetPDF';
import CardLinks from '../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../Components/PageHeader/PageHeader';

function ConsumerConfidenceReport() {
  const header1 = 'We are Big on Transparency'
  const paragraph1 = 'Learn about the levels of trace minerals and chemicals in your water';
  const linksFileName = 'News.json';
  const urlDomain = 'News';

  const [pageNumber, setPageNumber] = useState(1);
  const [selectFile, setSelectFile] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [recent, setRecent] = useState('');
  const fileName = 'CCR.json';

  useEffect(() => {
    const loadData = async () => {
      try {
        await Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
          const result = response.data;          
          const reports = result[result.length - 1];
          setSelectFile(result)
          setRecent(reports.file)

        });
      } catch (err) {
        console.log(err)
      };
    };
    loadData()
      .catch(console.error);
  }, []);

  useEffect(() => {
    setRecent(selectedFile);
    setPageNumber(1)
  }, [selectedFile])
  
  return (
      <>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="waterQuality" alt='formPage' />
      <div style={{ marginTop: '100px' }}>
        
        <Box marginTop={'10px'} marginLeft={'10px'}>
          <Autocomplete
            sx={{ width: '300px'}}
            id="prevReports"
            autoHighlight
            options={selectFile}
            disableClearable
            onChange={(e, value) => { 
              setSelectedFile(value.file)
            }}
            renderInput={(params) => <TextField {...params}
              onKeyPress={(e) => { e.preventDefault(); }}
              label="Previous Reports"/>}
          />
        </Box>
        
        <GetPDF fileName='CCR.json' controls={true} size={1000} page={pageNumber} recent={recent}/>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </div>
      </>    
  )
}

export default ConsumerConfidenceReport