import React from 'react'
import './NYKAYH2OCSS.css'
import PropTypes from 'prop-types';

const NYKAYH2O = (props) => {
  return (
    <div style={{ position: 'relative' }}>
    <div id={props.id === undefined ? '' : `${props.id}` } className={`NYK-bg-border ${props.direction ? 'right' : 'left'}`}>
    <div className='NYK-bg'>
      <h2 className='NYK-title' >{props.title}</h2>

      <div className={`NKY-svg-div ${props.direction ? 'right' : 'left'}`}>

        <svg xmlns="http://www.w3.org/2000/svg" className={`svgFile ${props.direction ? 'right' : 'left'}`} width="494" height="519" viewBox="0 0 494 519" fill="none">
            <g filter="url(#filter0_d_465_12)">
                <path d="M14.752 243.436C33.2641 201.177 74.3721 140.919 138.076 62.6619C201.779 -15.5955 275.556 -20.5518 359.405 47.793C443.254 116.138 485.451 202.482 485.996 306.825C486.54 411.168 438.898 474.296 343.071 496.208C247.243 518.12 172.105 514.207 117.658 484.469C63.2103 454.731 27.8193 417.69 11.4851 373.344C-4.84918 328.998 -3.76022 285.695 14.752 243.436Z" fill="url(#paint0_radial_465_12)"/>
                <clipPath id="maskImage" >
                    <path d="M -94 133 C -83 84 -68 35 66 -48 C 279 -168 386 -111 504 -34 C 542 33 858 263 501 400 C 520 387 438.898 474.296 343.071 496.208 C 247.243 518.12 172.105 514.207 117.658 484.469 C 63.2103 454.731 -58 455 -117 388 C -129 239 -118 245 -103 202 Z" />
                    </clipPath>
                    </g>
                    <defs>
                        <filter id="filter0_d_465_12" x="6.10352e-05" y="0.000244141" width="494" height="518.152" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="4" dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_465_12"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_465_12" result="shape"/>
                            </filter>
                            <radialGradient id="paint0_radial_465_12" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.053 327.319) rotate(-37.1722) scale(321.18 305.974)">
                                <stop offset="0.452085" stopColor="#205174"/>
                            <stop offset="1" stopColor="#07131C"/>
                            </radialGradient>
                            </defs>
                            <g clipPath="url(#maskImage)" transform="translate(0 -7)">
                                <image width="494" height="519" x="0" y="0" fill="none" className={`image__foreground ${props.direction ? 'right' : 'left'}`} href={props.img}  />
                                </g>
                                </svg>
                                </div>
                                
                                <p className={`NYK-paragraph ${props.direction ? 'right' : 'left'}`}>{props.paragraph}</p>
                                </div>
                                
                                             </div>
                                             {props.direction ? (
                                    <svg className='NYK-bg-bubble-A' width="844" height="811" viewBox="0 0 844 811" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M215.674 48.0158C290.893 24.1727 414.114 8.71236 585.419 1.67393C670.909 -1.83868 734.956 18.2979 777.767 61.8874C820.591 105.489 842.346 172.739 842.842 263.845C843.835 446.039 776.394 595.725 640.5 712.983C572.548 771.616 505.175 803.456 438.374 808.642C371.586 813.827 305.235 792.377 239.294 744.174C107.206 647.616 31.4326 548.442 11.673 446.689C-8.12331 344.747 2.1972 258.814 42.51 188.802C82.8523 118.739 140.564 71.8244 215.674 48.0158Z" fill="url(#paint0_radial_468_22)" fillOpacity="0.24" stroke="#4E90AC" strokeWidth="2"/>
                                    <defs>
                                    <radialGradient id="paint0_radial_468_22" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(572 111) rotate(116.565) scale(562.371 516.946)">
                                    <stop offset="0.171875" stopColor="#4E90AC" stopOpacity="0"/>
                                    <stop offset="0.776042" stopColor="#4E90AC"/>
                                    <stop offset="1" stopColor="#183B4A"/>
                                    </radialGradient>
                                    </defs>
                                    </svg>
                                    ) : (
                                        <svg className='NYK-bg-bubble-B' width="808" height="879" viewBox="0 0 808 879" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.4469 419.834C56.1687 347.153 124.44 243.415 230.344 108.585C283.196 41.297 340.104 5.67409 401.053 1.43063C462.02 -2.81406 527.286 24.3196 596.884 83.1116C736.068 200.685 806.089 349.182 806.992 528.669C807.444 618.42 787.9 690.329 748.458 744.492C709.024 798.644 649.621 835.166 570.142 854.001C410.934 891.73 286.31 884.938 196.105 833.878C105.732 782.723 47.092 719.066 20.0367 642.942C-7.03849 566.762 -5.2305 492.41 25.4469 419.834Z" fill="url(#paint0_radial_448_2467)" fillOpacity="0.24" stroke="#53A74D" strokeWidth="2"/>
                                    <defs>
                                        <radialGradient id="paint0_radial_448_2467" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(615 193.5) rotate(128.977) scale(734.496 675.169)">
                                            <stop offset="0.171875" stopColor="#55AC4E" stopOpacity="0"/>
                                            <stop offset="0.510417" stopColor="#55AC4E"/>
                                            <stop offset="0.859375" stopColor="#428F3B"/>
                                            <stop offset="1" stopColor="#0F310C"/>
                                            </radialGradient>
                                            </defs>
                                            </svg>
                                            )}
                                             </div>
  )
}

NYKAYH2O.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleOffsetX: PropTypes.string,
    titleOffsetY: PropTypes.string,
    img: PropTypes.node.isRequired,
    alt: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    direction: PropTypes.bool.isRequired,
    }
export default NYKAYH2O
