import React, { useEffect, useState } from "react";
import DiamondLine from "../DiamondLine/DiamondLine";
import borderImage from "../../../Assets/images/riverImage.jpg";
import searcyLogo from "../../../Assets/images/SWU_logo.png";
import "./FollowUsCSS.css";

const FollowPage = () => {
  const [fbPageEmbedState, setFbPageEmbedState] = useState(<></>);
  const [fbPostEmbedState, setFbPostEmbedState] = useState(<></>);

  /* Handle Window Dimensions */ 
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    /* Refresh Iframes */
    useEffect(() => {
    const fbPost = document.querySelector('#fbEmbeddedPost');
    const fbPage = document.querySelector('#fbEmbeddedPage');
  setFbPageEmbedState(<>
    <iframe
          title='facebook feed'
          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsearcywater%2F&tabs=timeline&width=${fbPage.offsetWidth}&height=${fbPage.offsetHeight}&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId`}
          width={fbPage.offsetWidth}
           height={fbPage.offsetHeight}
           />
    </>)
    setFbPostEmbedState(<>
    <iframe
            src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsearcywater%2Fposts%2Fpfbid0CW3zoyBFwCVsM7pbri9ykZVVRdX18ZUyzuHmeoz4GB3RY3JTs4yAYcRrfehCAS27l&show_text=true&width=${fbPost.offsetWidth - 5}`}
            title='facebook post'
            height={fbPage.offsetHeight}
            />
    </>)

  },[windowDimensions])
  
  return (
    <>

      <div className='overlay' style={{ WebkitMask: "linear-gradient(to bottom, transparent 5%, black 80%)", mask:"linear-gradient(to bottom, transparent 15%, black 80%)", background:
        'linear-gradient(to right, rgba(0,0,0,0.77) 0%, rgba(0,0,0,0.8) 22%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 60%,rgba(0,0,0,0.8) 78%, rgba(0,0,0,1) 100%)', backgroundSize: '100% 5px' }}>
        <img
        alt="Searcy Water Utilities Facebook Cover"
        src={borderImage}
        style={{ width: "100%", minWidth: '850px', height: '380px', position: "relative", opacity: '0.8', objectFit: 'cover', objectPosition: '0px -100px' }}
        loading='lazy'
      />
      </div>
      
      <div className="contentDiv" style={{ marginTop: '-100px' }} >
            <div id="facebookProfileContent" style={{ margin: '0 50px', height: '200px' }}>
              <img
              src={searcyLogo}
              alt='Searcy Water Utilities Icon'
              loading="lazy"
              style={{ height: '200px', width: '200px', border: '3px solid #333333', borderRadius: '50%', objectFit: 'contain', backgroundColor: 'white', position: 'absolute', }}
              />
          </div>

      <div className="followContent">
        
      <p>Stay informed and up-to-date on important events and changes affecting your water service! Follow us on Facebook to receive timely updates on water main breaks, line flushing, office closures, and billing changes</p>
      <p>Don't miss out on essential information - <a href='https://www.facebook.com/searcywater/'><b>connect with us on Facebook today!</b></a></p>

      <DiamondLine title="Follow Our Page!" reverse={true} />
      <div id='facebookContent'>
          <div id='fbEmbeddedPost'>
            {fbPostEmbedState}
          </div>
          
          <div id='fbEmbeddedPage'>
            {fbPageEmbedState}
          </div>
        </div>
      </div>
      </div>   
    </>
  );
};

export default FollowPage;
