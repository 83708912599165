import React from 'react'
import PageHeader from '../../Components/PageHeader/PageHeader';
import CardLinks from '../../Components/CardLinks/CardLinks';
import StyledBlock from '../../Components/StyledBlocks/StyledBlock';


function Services() {
  const header1 = 'In Need of Service Changes?';
  const paragraph1 = 'Whether you are a new customer or changing your address, Searcy Water Utilities has you covered!';
      
  const linksFileName = 'Services.json';
  const urlDomain = 'Services';
  const styledBlockFileName = 'StyledBlocks/Services.json';

  return (
    <div className='customerServices'>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="services" />
      <div className='subPageContainer'>
      <StyledBlock fileName={styledBlockFileName}/>
      </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain}/>
    </div>
  )
}

export default Services