import React from 'react'
import CardLinks from '../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import StyledBlock from '../../../Components/StyledBlocks/StyledBlock';

const Administation = () => {
    const header1 = 'Meet our Managers!'
    const paragraph1 = 'The people who supervise daily operations';
    const linksFileName = 'AboutUs.json';
    const styledBlockFileName = 'StyledBlocks/Administration.json';
    const urlDomain = 'AboutUs';

    return (
    <div className='aboutUs admin'>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="admin" alt='subPage' />
            <div className='subPageContainer'>
                <StyledBlock fileName={styledBlockFileName} selectionList="Admins" imgLocation='Admins'/>
            </div>
            
      <div className='aboutUs'>
        <CardLinks urlDomain={urlDomain} fileName={linksFileName}/>
        </div>
      </div> 
  )
}

export default Administation
