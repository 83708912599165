import React, { useEffect, useState } from "react";
// import { Box, Modal, Typography } from "@mui/material";
// import { HeaderSX } from './HeaderSX.jsx';
import logo from "../../Assets/images/SWU_logo.png";
// import { IoIosCloseCircle } from "react-icons/io";
// import { BsExclamationCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import PropTypes from 'prop-types';
import './HeaderCSS.css'

const Header = (props) => {
  // const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  // const [shutoff, setShutoff] = useState(true);
  // const [shutoffOpen, setShutoffOpen] = useState(false);
  const width = 750;
  const height = 925;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  const handleOpenPayments = () => {
    window.open("https://wwwi.searcywater.org:8443/UBWebWithMobileTC/AccountAccessStart-en_US.html", "_blank",
        `toolbar=no,scrollbars=yes,resizable=no, width=${width}, height=${height}, left=${left}, top=${top}`)
  }
  // const handleClose = () => setOpen(false);
  // const handleShutoffClose = () => setShutoffOpen(false);
  const [location, setLocation] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const setNewLocation = (newLocation) =>{
      setLocation(newLocation);
    }
    
    const path = window.location.pathname;
    const secondSlashIndex = path.indexOf('/', path.indexOf('/') + 1);
    const extractedText = path.substring(0, secondSlashIndex >= 0 ? secondSlashIndex : path.length);
    
    setLocation(extractedText);
    setNewLocation(extractedText);
  },[location, navigate])

  return (
    <div id='topNav'>
      <div className='navigation'>
        <a className='logo' href="/">
          <img
            id="logo"
            src={logo}
            alt="logo"
          />
            </a>
      <div className='hamburgerMenu' onClick={() => {
            if (active){
              setActive(false);
            }}}>
        <HamburgerMenu active={active} setActive={setActive} handlePayments={handleOpenPayments} disablePayments={props.disablePayments}/>
      </div>
        <nav className='links'>
          <Link className={location === '/Services' ? 'navLink active' : 'navLink'} to='/Services'>Services</Link>
          <Link className={location === '/News' ? 'navLink active' : 'navLink'} to='/News'>News & Info</Link>
          <Link className={location === '/AboutUs' ? 'navLink active' : 'navLink'} to='/AboutUs'>About Us</Link>
          <a className='navLink' href="https://searcywater.maps.arcgis.com/apps/webappviewer/index.html?id=b0da5f679c5446308730235c9e328c4b" target="_blank" rel="noreferrer">GIS Mapping</a>
          <button className={`payBill btn ${props.disablePayments ? 'disabledBtn': ''}`} onClick={handleOpenPayments} disabled={props.disablePayments} >Payments</button>

           {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="Payments Modal"
              aria-describedby="Modal for entering payment details"
            >
              <Box
                sx={HeaderSX.paymentPortal}
              >
                {shutoff ? (
                  <button className="shutoffBtn" onClick={() =>{setShutoffOpen(!shutoffOpen)}}>
                  <BsExclamationCircle style={{ color: 'red', fontSize: '1.3rem', display: 'block', margin: '-10px 10px' }} /> 
                  <span style={{  display: 'block', margin: '-24px 10px -24px 38px', fontSize: '1.3rem' }}>Shutoff Information</span>
                </button>
                ) : null}
                <IoIosCloseCircle className='closeBtn' onClick={handleClose} aria-label="Close Modal Button"/>
                <div style={{width: '100%', height: 'calc(100% - 75px)', margin: '65px 5px 0 2.5px'}}>
                <Modal
              open={shutoffOpen}
              onClose={handleShutoffClose}
              aria-labelledby="Water Service Shutoff Modal"
              aria-describedby="Modal describing actions to take for imminent water service shutoffs"
            >
              <Box sx={HeaderSX.shutoffModal}>
                <Typography>
                  If your service is at risk of shutoff, call us at <a href="tel: (501) 268-2481">501-268-2481</a> to ensure that your service is restored in a timely matter
                </Typography>
              </Box>
              </Modal>
                  <iframe 
                  width={'100%'} 
                  height={'100%'} 
                  style={{ border: "transparent solid 3px" }} 
                  src='https://wwwi.searcywater.org:8443/UBWebWithMobileTC/AccountAccessStart-en_US.html'
                  title='AccountAccessStart'
                  ></iframe>
                </div>
              </Box>
            </Modal> */}
            </nav>
      </div>
    </div>
    
  );
};

Header.propTypes = {
  disablePayments: PropTypes.bool.isRequired,
  }

export default Header;
