import { Link } from 'react-router-dom';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { MdAccessTimeFilled, MdOutlineWater, MdWaterDrop } from 'react-icons/md';
import './HistoryItem.css'

function switchIcon(icon) {
    switch (icon) {
        case 1:
            return <MdAccessTimeFilled />
        case 2:
            return <MdOutlineWater />
        case 3:
            return <MdWaterDrop />
        default:
            return "none"
    }
}

function HistoryItem({ id, name, date, subText, icon }) {
    const Icon = switchIcon(icon);

    return (
        <VerticalTimelineElement
            contentStyle={{ background: "#d5e8f7" }}
            contentArrowStyle={{ borderRight: '7px solid #d5e8f7', margin: '0 -24px' }}
            date={date}
            iconStyle={{ background: "#1B2D72", color: '#d5e8f7', }}
            icon={Icon}>
                <Link to={`/History/${id}`}>
                    <div className='vertical-timeline-element-content-div'>
                    <h2 className="vertical-timeline-element-title">{name}</h2>
                    <p className='subText'>{subText}</p>
                    <span className='vertical-timeline-element-moreContent'>More<span>...</span></span>
                    </div>
                </Link>            
        </VerticalTimelineElement>
    );
}

export default HistoryItem