import React, { useEffect, useState } from 'react'
import { FormSX } from '../../Services/ServicePages/FormSX';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import MuiAlert from '@mui/material/Alert';
import { Button, Grid, Paper, Snackbar, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input'
import ReCAPTCHA from "react-google-recaptcha";
import Axios from 'axios'
import CardLinks from '../../../Components/CardLinks/CardLinks';
import PageHeader from '../../../Components/PageHeader/PageHeader';

const ContactUs = () => {
  const [recaptchaKey, setRecaptchaKey] = useState(); const header1 = "We'd Love to Hear from You"
  const paragraph1 = 'Questions? Comments? Concerns?';
  const paragraph2 = "Contact us, we'd love to hear how we can better assist you";
  const linksFileName = 'AboutUs.json';
  const urlDomain = 'AboutUs';

  const [from, setFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sentData, setSentData] = useState('');
  const [open, setOpen] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);


  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [serviceAddress, setServiceAddress] = useState('');

  const runRecaptcha = async () => {
    try {
      await Axios.get("https://searcywaterutilitiesservice.onrender.com/api/recaptcha").then((response) => {
        const result = response.data
        setRecaptchaKey(result)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    runRecaptcha()
  }, [])

  useEffect(() => {
    setSentData(JSON.parse(
      `{
        "from" : "${from}",
        "subject" : "${subject}",
        "message" : "${message}",
        "address" : "${serviceAddress}",
        "name" : "${name}",
        "phonenumber" : "${phoneNumber}"
      }`))
  }, [from, subject, message, serviceAddress, name, phoneNumber]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function reCaptcha() {
    setCaptchaVerified(!captchaVerified);
  }

  const sendData = async () => {
    Axios.post("/contactMail", { sentData }).then((res) => {
      if (res.status === 200) {
        alert('Thank you for your contact. We will get back to you as soon as possible');
        console.log(res);
        document.getElementById('contactForm').reset();
        setPhoneNumber('');
      }
    }
    )
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const { handleSubmit } = useForm();
  const onSubmit = () => {
    if (from !== '' && subject !== '' && message !== '') {
      sendData();
    }
    else {
      setOpen(true);
    }
  }

  const phoneChange = (newValue) => {
    setPhoneNumber(newValue);
  }

  return (
    <div>
      <PageHeader header1={header1} paragraph1={paragraph1} paragraph2={paragraph2} imgArr="contact" alt='formPage' />
      <div className='ServiceForm'>
        <Paper elevation={10} sx={FormSX.paperStyle}>
          <form onSubmit={handleSubmit(onSubmit)} id='contactForm'>
            <FormControl fullWidth>
              <Typography variant="h1">Contact Us</Typography>
              <hr style={FormSX.headerLine} />
              <Typography variant="p">At Searcy Water Utilities, we are here to provide you with the best possible service and address any inquiries or concerns you may have. As the leading authority in water management, we strive to maintain the highest standards of customer satisfaction.</Typography>
              <Typography variant="p">Whether you need assistance with water <a href="/Services/BankDraftForm">billing</a>, reporting a <a href="/Services/LeakForm">water leak</a>, requesting a <a href="/Services/NewCustomerForm">new service connection</a>, or seeking information about <a href="/News/Conservation">water conservation</a>, our dedicated team is ready to assist you. Our knowledgeable staff is well-equipped to answer your questions and provide guidance on all matters related to water supply, quality, and infrastructure.</Typography>
              <Typography variant="p">By filling out the contact form below, you are taking the first step in reaching out to us. Please provide us with your contact details and a brief description of your query, and we will get back to you promptly. Your feedback is valuable to us, and we are committed to resolving any issues or providing the necessary information you seek.</Typography>
              <Typography variant="p">Thank you for choosing the Water Municipality as your trusted water service provider. We look forward to hearing from you and serving you better.</Typography>
              <Typography variant="p">Our business hours are Monday - Friday, 8:00 am - 4:30 pm</Typography>
              <Typography variant="p">In case of after-hour emergencies, please call <a href="tel: (501) 268-2481">(501) 268-2481</a></Typography>

              <div className='formContainer' style={{ justifyContent: 'center', display: 'flex' }}>
                <Grid container spacing={1}>
                  <Grid item sm={6} md={8} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Name"
                      placeholder="Enter Name"
                      required
                      onChange={(e) => { setName(e.target.value) }}
                    />
                  </Grid>
                  <Grid item sm={6} md={4} sx={FormSX.input}>
                    <MuiTelInput
                      sx={{ width: '100%' }}
                      disableDropdown
                      onlyCountries={'US'}
                      label="Phone Number"
                      onChange={phoneChange}
                      value={phoneNumber} />
                  </Grid>
                  <Grid item xs={12} md={6} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="serviceAddress"
                      label="Address"
                      placeholder="Enter Address"
                      onChange={(e) => { setServiceAddress(e.target.value) }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={FormSX.input}>
                    <TextField
                      fullWidth
                      id="from"
                      label="Email Address"
                      placeholder="Enter Your Email Address"
                      required
                      onChange={(e) => { setFrom(e.target.value) }} />
                  </Grid>
                  <Grid container sx={FormSX.messageContainer} spacing={1}>
                    <Grid item xs={12} className='messageSubject' sx={FormSX.input}>
                      <TextField
                        fullWidth
                        id="subject"
                        placeholder="Enter message subject"
                        label="Subject"
                        onChange={(e) => { setSubject(e.target.value) }}
                      />
                    </Grid>
                    <Grid item xs={12} className='messageText' sx={FormSX.input}>
                      <TextField
                        fullWidth
                        id="message"
                        multiline
                        minRows={4}
                        placeholder="Enter your message"
                        label="Message"
                        onChange={(e) => { setMessage(e.target.value) }} />
                    </Grid>
                  </Grid>
                  {(recaptchaKey !== undefined) ?
                    <ReCAPTCHA
                      sitekey={recaptchaKey}
                      onChange={reCaptcha}
                    />
                  : null }
                </Grid>
              </div>

              <Button
                disabled={!captchaVerified}
                id="submit"
                variant="contained"
                style={{ marginTop: 10 }}
                type='submit' color='primary'
                fullWidth
              >Submit</Button>
            </FormControl>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Please Fill Out All Required Fields to Submit an Email
              </Alert>
            </Snackbar>
          </form>
        </Paper>
      </div>
      <CardLinks fileName={linksFileName} urlDomain={urlDomain} />
    </div>
  )
}

export default ContactUs
