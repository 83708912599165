import '../../App.css';
import { Alert, Autocomplete, Button, Snackbar, TextareaAutosize, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Components/Auth';
import GoToTop from '../../Components/GoToTop';
import Axios from 'axios';
import Grid from '@mui/material/Grid';

const icons = [
  { id: 1, label: 'Access' },
  { id: 2, label: 'water' },
  { id: 3, label: 'Opacity' },
]

const AdminForms = () => {

  const [value, setValue] = useState(icons[0])
  const [alertMessage, setAlertMessage] = useState('')
  const [open, setOpen] = useState(false);
  const [item1, setItem1] = useState('');
  const [item2, setItem2] = useState('');
  const [item3, setItem3] = useState('');
  const [item4, setItem4] = useState('');
  const [item5, setItem5] = useState('');
  const [message, setMessage] = useState('');
  const [pdf, setPdf] = useState('');
  const [image, setImage] = useState({ preview: '', data: '' });
  const [readData, setReadData] = useState([]);
  const [show, setShow] = useState('');
  const [fileName, setFileName] = useState('');
  const [writeData, setWriteData] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();
  const [over, setOver] = useState(false);
  const [maintColor, setMaintColor] = useState("primary");

  useEffect(() => {
    const loadData = () => {
      Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { fileName }).then((response) => {
        const result = response.data;
        setReadData(result);
      })
    };
    if (fileName !== '') {
      try {
        loadData();
      } catch (err) {
        console.log(err)
      }
    } else if (fileName === '') {
      setReadData([])
    }
  }, [fileName]);

  useEffect(() => {
    if (fileName === 'HolidayMaint.json') {
      console.log(readData)
      if (readData.length !== 0) {
        if (readData[2].override) {
          setOver(readData[2].override)
        }
        if (!readData[2].override) {
          console.log(readData)
        }
      }
    }
  }, [fileName, readData])

  useEffect(() => {
    over ? setMaintColor('error') : setMaintColor('primary')
  }, [over])

  useEffect(() => {
    show === 'services' ? setFileName('servicesList.json') :
      show === 'banner' ? setFileName('BannerImages.json') :
        show === 'tips' ? setFileName('ConservationTips.json') :
          show === 'history' ? setFileName('HistoryList.json') :
            show === 'CCR' ? setFileName('CCR.json') :
              show === 'BillingCalendar' ? setFileName('BillingCalendar.json') :
                show === 'Rates' ? setFileName('Rates.json') :
                  show === 'maintenance' ? setFileName('HolidayMaint.json') :
                    show === 'message' ? setFileName('HolidayMaint.json') :
                      setShow('');
    setItem1('');
    setItem2('');
    setItem3('');
    setItem4('');
    setItem5('');
    setImage('');
  }, [show])

  useEffect(() => {
    setItem3(`"icon" : ${value.id},`)
  }, [value])

  useEffect(() => {

    setWriteData(
      item1 +
      item2 +
      item3 +
      item4 +
      item5
    )
  }, [item1, item2, item3, item4, item5, value]);

  const handleLogout = () => {
    auth.logout()
    navigate('/');
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {

    }
    setOpen(false);
  }

  const clearFields = () => {
    const inputs = document.querySelectorAll('#serviceName, #serviceSubtext, #servicePage, #bannerName, #bannerSubtext, #tipName, #tipSubtext, #historyName, #historyDate, #historyIcon, #historySubtext');

    inputs.forEach(input => {
      input.value = '';
    })
  }

  const sendData = async () => {
    if (readData.length) {
      console.log(readData.length)
      try {
        await Axios.post("https://searcywaterutilitiesservice.onrender.com/writeData", { fileName, readData }).then((response) => {
          setItem5(null);
          setImage('');
          clearFields();
          console.log(response.status)
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const createMessage = async () => {
    if (message !== '') {
      try {
        console.log(message);
        await Axios.post('https://searcywaterutilitiesservice.onrender.com/createMessage', {message}).then((response) => {
        clearFields();  
        console.log('Message Sent: ', response.status)
        })
      } catch (error) {
        console.log(error);
      }
    }
  }

  const toggleOverride = async () => {
    try {
      await Axios.post("https://searcywaterutilitiesservice.onrender.com/override").then((response) => {
        let result = response.data
        console.log(result)
        setOver(result[2].override);
      })
    } catch (err) {
      console.log(err)
    }
  }

  const uploadFile = async () => {
    try {
      let formData = new FormData();
      formData.append('file', image.data);
      console.log(image.data)
      Axios.post("https://searcywaterutilitiesservice.onrender.com/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        console.log(response);
      })
    } catch (err) {
      console.log(err)
    }
  }

  const uploadPDF = async () => {
    try {
      let formData = new FormData();
      formData.append('file', pdf.data);
      console.log(formData)
      Axios.post("https://searcywaterutilitiesservice.onrender.com/fileUpload", formData, {
        method: "POST",
        mode: "cors",
        headers:
        {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        console.log(response.status);
      })
    } catch (err) {
      console.log(err)
    }
  }

  const deleteRequestHandler = async (name) => {
    await Axios.delete(
      "https://searcywaterutilitiesservice.onrender.com/deleteImage/" + name
    ).then((response) => {
      console.log(response.status)
    })
  };

  const deleteMessageRequestHandler = async () => {
    await Axios.delete(
      "https://searcywaterutilitiesservice.onrender.com/deleteMessage/"
    ).then((response) => {
      console.log(response.status)
    })    
  };

  const imageChangeHandler = (e) => {
    const fName = e.target.files[0].name;
    const fNameArr = fName.split(".");
    const fNameExt = fNameArr.pop().toLowerCase();
    const newName = fNameArr[0] + "." + fNameExt;
    const nFile = new File([e.target.files[0]], newName)
    if (!e.target.files[0]) {
      console.log("no file selected");
    } else {
      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: nFile,
      }
      console.log(img.data)
      setItem5(`"image" : "${img.data.name}"}`);
      setImage(img);
    }
  }

  const pdfChangeHandler = (e) => {
    if (!e.target.files[0]) {
      console.log("no file selected");
    } else {
      const pdfFile = {
        data: e.target.files[0],
      }
      setItem5(`"file" : "${pdfFile.data.name}"}`);
      setPdf(pdfFile);
    }
  }

  const maintenanceHandler = async () => {
    if (readData && fileName === "HolidayMaint.json") {
      toggleOverride();
    }
  }

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (e) => {

    readData.push(JSON.parse(writeData));
    console.log(readData);

    if (show !== 'services') {
      if (item5 === '') {
        setAlertMessage('Image Must Be Selected');
        setOpen(true);
      } else {
        if (pdf === '') {
          uploadFile();
          sendData();
        } else {
          uploadPDF();
          sendData();
        }
      }
    }
    else {
      sendData();
    }

  }


  return (
    <div style={{ marginTop: "40px" }}>
      Welcome, {auth.user}!
      <Button onClick={handleLogout}>Log Out</Button>
      <Button onClick={() => {
        (show !== 'services' ? setShow('services') : setShow(''))
      }}>Show Services</Button>
      <Button onClick={() => {
        (show !== 'banner' ? setShow('banner') : setShow(''));
      }}>Show Banner Items</Button>
      <Button onClick={() => {
        (show !== 'tips' ? setShow('tips') : setShow(''));
      }}>Show Conservation Tips</Button>
      <Button onClick={() => {
        (show !== 'history' ? setShow('history') : setShow(''));
      }}>Show History</Button>
      <Button onClick={() => {
        (show !== 'CCR' ? setShow('CCR') : setShow(''));
      }}>CCR</Button>
      <Button onClick={() => {
        (show !== 'BillingCalendar' ? setShow('BillingCalendar') : setShow(''));
      }}>Billing Calendar</Button>
      <Button onClick={() => {
        (show !== 'Rates' ? setShow('Rates') : setShow(''));
      }}>Rates</Button>
      <Button onClick={() => {
        (show !== 'maintenance' ? setShow('maintenance') : setShow(''));
      }}>Maintenance</Button>
      <Button onClick={() => {
        (show !== 'message' ? setShow('message') : setShow(''));
      }}>Message</Button>
      {
        (show === 'services') ?

          <Grid id='Services' style={{ width: '400px', marginBottom: '50px' }}>
            <h2>Existing Services</h2>
            {readData.map((data, idx) => {
              return (
                <div key={idx}>
                  <div style={{ marginBottom: '10px' }}>
                    <p><b>Service Name:</b> {data.name}</p>
                    <p><b>Service Subtext:</b> {data.subtext}</p>
                    <p><b>Page:</b> {data.page}</p>
                    <p><b>Id:</b> {idx}</p>
                  </div>
                  <Button variant="outlined" onClick={() => {
                    readData.splice(idx, 1)
                    console.log(readData)
                    sendData();

                  }}>Delete Entry</Button>
                </div>
              )
            })}

            <Grid align='left'>
              <h2>Create New Service</h2>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField id="serviceName"
                style={{ marginBottom: "10px" }}
                variant='standard'
                autoComplete=""
                label="Service Name"
                placeholder="Enter Service Name"
                fullWidth
                {...register("serviceName", { required: "Required Field" })}
                error={!!errors?.serviceName}
                helperText={errors?.serviceName ? errors.serviceName.message : null}
                onChange={(e) => { setItem1(`{"name":"${e.target.value}",`) }}
              />
              <TextField id="serviceSubtext"
                style={{ marginBottom: "10px" }}
                variant='standard'
                autoComplete=""
                label="Service Subtext"
                placeholder="Enter Service Subtext"
                fullWidth
                {...register("serviceSubtext", { required: "Required Field" })}
                error={!!errors?.serviceSubtext}
                helperText={errors?.serviceSubtext ? errors.serviceSubtext.message : null}
                onChange={(e) => { setItem2(`"subtext":"${e.target.value}",`) }}
              />
              <TextField id="servicePage"
                style={{ marginBottom: "10px" }}
                variant='standard'
                autoComplete=""
                label="Service Page"
                placeholder="Enter Service Page"
                fullWidth
                {...register("servicePage", { required: "Required Field" })}
                error={!!errors?.servicePage}
                helperText={errors?.servicePage ? errors.servicePage.message : null}
                onChange={(e) => { setItem3(`"page":"${e.target.value}"}`) }}
              />
              <Button
                id="submit"
                variant="contained"
                style={{ marginTop: 10 }}
                type='submit' color='primary'
                fullWidth
              >Submit Change</Button>
            </form>
          </Grid>
          : null
      }
      {(show === 'banner') ?

        <Grid id="Carousel" style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Existing Banner Items</h2>
          {readData.map((data, idx) => {
            return (
              <div key={idx}>
                <div style={{ marginBottom: '10px' }}>
                  <p><b>Banner Name:</b> {data.name}</p>
                  <p><b>Banner Subtext:</b> {data.subtext}</p>
                  <p><b>Image:</b> {data.image}</p>
                </div>
                <Button variant="outlined" onClick={() => {
                  readData.splice(idx, 1);
                  deleteRequestHandler(data.image);
                  sendData();

                }}>Delete Entry</Button>
              </div>
            )
          })}
          <Grid align='left'>
            <h2>Create New Banner Item</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField id="bannerName"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="Banner Name"
              placeholder="Enter Banner Name"
              fullWidth
              {...register("bannerName", { required: "Required Field" })}
              error={!!errors?.bannerName}
              helperText={errors?.bannerName ? errors.bannerName.message : null}
              onChange={(e) => { setItem1(`{"name" : "${e.target.value}",`) }}
            />
            <TextareaAutosize id="bannerSubtext"
              minRows={3}
              style={{ width: "100%", borderRadius: "3px" }}
              sx={{ marginBottom: "10px", fontSize: "14px" }}
              variant='standard'
              autoComplete=""
              label="Carousel Subtext"
              placeholder="Enter Carousel Subtext"
              onChange={(e) => { setItem4(`"subText" : "${e.target.value}",`) }}
            />
            {image.preview && <img src={image.preview} alt="Preview" width='100' height='100' />}
            <Button
              id="historyImage"
              variant="contained"
              component="label"
              fullWidth
            >
              Upload Image (required)
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={imageChangeHandler}
              />
            </Button>
            <Button
              id="submit"
              variant="contained"
              style={{ marginTop: 10 }}
              type='submit' color='primary'
              fullWidth
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }

      {(show === 'tips') ?

        <Grid id="Tips" style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Existing Conservation Tips</h2>
          {readData.map((data, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '10px' }}>
                <p><b>Tip Name:</b> {data.name}</p>
                <p><b>Tip Subtext:</b> {data.subText}</p>
                <p><b>Image:</b> {data.image}</p>
                <Button variant="outlined" onClick={() => {
                  readData.splice(idx, 1);
                  deleteRequestHandler(data.image);
                  sendData();

                }}>Delete Entry</Button>
              </div>

            )
          })}

          <Grid align='left'>
            <h2>Create New Tip</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField id="tipName"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="Tip Name"
              placeholder="Enter Tip Name"
              fullWidth
              {...register("tipName", { required: "Required Field" })}
              error={!!errors?.tipName}
              helperText={errors?.tipName ? errors.tipName.message : null}
              onChange={(e) => { setItem1(`{"name" : "${e.target.value}",`) }}
            />
            <TextareaAutosize id="tipSubtext"
              minRows={3}
              style={{ width: "100%", borderRadius: "3px" }}
              sx={{ marginBottom: "10px", fontSize: "14px" }}
              variant='standard'
              autoComplete=""
              label="Tip Subtext"
              placeholder="Enter Tip Subtext"
              onChange={(e) => { setItem4(`"subText" : "${e.target.value}",`) }}
            />
            {image.preview && <img src={image.preview} alt="Preview" width='100' height='100' />}
            <Button
              id="tipImage"
              variant="contained"
              component="label"
              fullWidth
            >
              Upload Image (required)
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={imageChangeHandler}
              />
            </Button>
            <Button
              id="submit"
              variant="contained"
              style={{ marginTop: 10 }}
              type='submit' color='primary'
              fullWidth
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }

      {(show === 'history') ?

        <Grid style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Existing History</h2>
          {readData.map((data, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '10px' }}>
                <p><b>History Name:</b> {data.name}</p>
                <p><b>Date:</b> {data.date}</p>
                <p><b>History Subtext:</b> {data.subText}</p>
                <p><b>Image:</b> {data.image}</p>
                <p><b>Icon:</b> {data.icon}</p>
                <Button variant="outlined" onClick={() => {
                  readData.splice(idx, 1);
                  deleteRequestHandler(data.image);
                  sendData();

                }}>Delete Entry</Button>
              </div>
            )
          })}

          <Grid align='left'>
            <h2>Create New History Item</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField id="historyName"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="History Name"
              placeholder="Enter History Name"
              fullWidth
              {...register("historyName", { required: "Required Field" })}
              error={!!errors?.historyName}
              helperText={errors?.historyName ? errors.historyName.message : null}
              onChange={(e) => { setItem1(`{"name" : "${e.target.value}",`) }}
            />
            <TextField id="historyDate"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="History Date"
              placeholder="Enter History Date"
              fullWidth
              {...register("historyDate", { required: "Required Field" })}
              error={!!errors?.historyDate}
              helperText={errors?.historyDate ? errors.historyDate.message : null}
              onChange={(e) => { setItem2(`"date" : "${e.target.value}",`) }}
            />
            <Autocomplete id="historyIcon"
              value={value}
              isOptionEqualToValue={(icons, value) => icons.id === value.id}
              disableClearable
              options={icons}
              sx={{ width: "100%", marginBottom: "10px" }}
              renderInput={(params) => <TextField {...params} label="Select Icon" />}
              onChange={(e, newValue) => { setValue(newValue) }}
            />
            <TextareaAutosize id="historySubtext"
              minRows={3}
              style={{ width: "100%", borderRadius: "3px" }}
              sx={{ marginBottom: "10px", fontSize: "14px" }}
              variant='standard'
              autoComplete=""
              label="History Subtext"
              placeholder="Enter History Subtext"
              onChange={(e) => { setItem4(`"subText" : "${e.target.value}",`) }}
            />

            {image.preview && <img src={image.preview} alt="Preview" width='100' height='100' />}
            <Button
              id="historyImage"
              variant="contained"
              component="label"
              fullWidth
            >
              Upload Image (required)
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={imageChangeHandler}
              />
            </Button>
            <Button
              id="submit"
              variant="contained"
              style={{ marginTop: 10 }}
              type='submit' color='primary'
              fullWidth
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }
      {(show === 'CCR') ?

        <Grid id="ccr" style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Existing CCR</h2>
          {readData.map((data, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '10px' }}>
                <p><b>CCR Name:</b> {data.label}</p>
                <p><b>CCR File:</b> {data.file}</p>
                <Button variant="outlined" onClick={() => {
                  readData.splice(idx, 1);
                  deleteRequestHandler(data.file);
                  sendData();

                }}>Delete Entry</Button>
              </div>

            )
          })}

          <Grid align='left'>
            <h2>Add New CCR</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField id="CCRLabel"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="Display Name"
              placeholder="Enter Display Name"
              fullWidth
              {...register("CCRLabel", { required: "Required Field" })}
              error={!!errors?.CCRLabel}
              helperText={errors?.CCRLabel ? errors.CCRLabel.message : null}
              onChange={(e) => { setItem1(`{"label" : "${e.target.value}",`) }}
            />
            <Button
              id="CCRFile"
              variant="contained"
              component="label"
              fullWidth
            >
              Upload PDF (required)
              <input
                accept="PDF/*"
                type="file"
                hidden
                onChange={pdfChangeHandler}
              />
            </Button>
            <Button
              id="submit"
              variant="contained"
              style={{ marginTop: 10 }}
              type='submit' color='primary'
              fullWidth
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }
      {(show === 'BillingCalendar') ?

        <Grid id="billingCalendar" style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Current Billing Calendar</h2>
          {readData.map((data, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '10px' }}>
                <p><b>Calendar Name:</b> {data.label}</p>
                <p><b>Calendar File:</b> {data.file}</p>
                <Button variant="outlined" onClick={() => {
                  readData.splice(idx, 1);
                  deleteRequestHandler(data.file);
                  sendData();

                }}>Delete Entry</Button>
              </div>

            )
          })}

          <Grid align='left'>
            <h2>Add New Calendar</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField id="CalendarLabel"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="Display Name"
              placeholder="Enter Display Name"
              fullWidth
              {...register("CalendarLabel", { required: "Required Field" })}
              error={!!errors?.CalendarLabel}
              helperText={errors?.CalendarLabel ? errors.CalendarLabel.message : null}
              onChange={(e) => { setItem1(`{"label" : "${e.target.value}",`) }}
            />
            <Button
              id="CalendarFile"
              variant="contained"
              component="label"
              fullWidth
            >
              Upload PDF (required)
              <input
                accept="PDF/*"
                type="file"
                hidden
                onChange={pdfChangeHandler}
              />
            </Button>
            <Button
              id="submit"
              variant="contained"
              style={{ marginTop: 10 }}
              type='submit' color='primary'
              fullWidth
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }
      {(show === 'Rates') ?

        <Grid id="Rates" style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Current Rates</h2>
          {readData.map((data, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '10px' }}>
                <p><b>Rate File Name:</b> {data.label}</p>
                <p><b>Rate File:</b> {data.file}</p>
                <Button variant="outlined" onClick={() => {
                  readData.splice(idx, 1);
                  deleteRequestHandler(data.file);
                  sendData();

                }}>Delete Entry</Button>
              </div>

            )
          })}

          <Grid align='left'>
            <h2>Add New Rates</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField id="RatesLabel"
              style={{ marginBottom: "10px" }}
              variant='standard'
              autoComplete=""
              label="Display Name"
              placeholder="Enter Display Name"
              fullWidth
              {...register("RatesLabel", { required: "Required Field" })}
              error={!!errors?.RatesLabel}
              helperText={errors?.RatesLabel ? errors.RatesLabel.message : null}
              onChange={(e) => { setItem1(`{"label" : "${e.target.value}",`) }}
            />
            <Button
              id="RatesFile"
              variant="contained"
              component="label"
              fullWidth
            >
              Upload PDF (required)
              <input
                accept="PDF/*"
                type="file"
                hidden
                onChange={pdfChangeHandler}
              />
            </Button>
            <Button
              id="submit"
              variant="contained"
              style={{ marginTop: 10 }}
              type='submit' color='primary'
              fullWidth
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }
      {(show === 'maintenance') ?

        <Grid id="Rates" style={{ width: '400px', marginBottom: '50px' }}>
          <Grid align='left'>
          </Grid>
          <form>
            <Button
              id="maintToggle"
              variant="contained"
              component="label"
              color={maintColor}
              fullWidth
              onClick={(e) => { maintenanceHandler() }}
            >
              Toggle Maintenance
            </Button>
          </form>
        </Grid>
        : null
      }

      {(show === 'message') ?
        <Grid style={{ width: '400px', marginBottom: '50px' }}>
          <h2>Existing Message</h2>
              <div style={{ marginBottom: '10px' }}>
                <p><b>Message:</b> {JSON.stringify(readData[5])}</p>
                <Button variant="outlined" onClick={() => {                  
                  deleteMessageRequestHandler();
                }}>Delete Entry</Button>
              </div>          

          <Grid align='left'>
            <h2>Create New Message</h2>
            <p>Only one custom message may be used at a time.</p>
            <p>No Special Characters Allowed!</p>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>

            <TextareaAutosize id="messages"
              minRows={3}
              style={{ width: "100%", borderRadius: "3px" }}
              sx={{ marginBottom: "10px", fontSize: "14px" }}
              variant='standard'
              autoComplete=""
              label="Message"
              placeholder="Enter Message"
              onChange={(e) => { setMessage(e.target.value) }}
            />

            <Button
              id="MessageSubmit"
              variant="contained"
              style={{ marginTop: 10 }}
              color='primary'
              fullWidth
              onClick={() => createMessage()}
            >Submit Change</Button>
          </form>
        </Grid>
        : null
      }

      <Snackbar open={open} variant="filled" autoHideDuration={8000} onClose={handleClose}>
        <Alert severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>

      <GoToTop />
    </div>
  )
}

export default AdminForms;