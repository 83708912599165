import React from 'react'
import PageHeader from '../../Components/PageHeader/PageHeader'
import CardLinks from '../../Components/CardLinks/CardLinks'
import StyledBlock from '../../Components/StyledBlocks/StyledBlock'
import FireFighter from '../../Assets/images/NYKAYH2O/firefighter.png'
import NYKAYH2O from '../../Components/NYKAYH2O/NYKAYH2O'

const AboutUs = () => {    
    const header1 = 'Searcy Water Utilities'
    const paragraph1 = 'Dedicated to providing Safe and Reliable Water Services for Our Community';
    const linksFileName = 'AboutUs.json';
    const styledBlockFileName = 'StyledBlocks/AboutUs/AboutUs1.json';
    const styledBlockFileName2 = 'StyledBlocks/AboutUs/AboutUs2.json';
    const urlDomain = 'AboutUs';
    const NYKtitle = 'Average Cost Savings';
    const NYKparagraph = "Searcy Water Utilities' distribution system spans over 200 miles, featuring 1,400 fire hydrants and 11,000 service connections, ensuring that our community receives water that nourishes our bodies and minds.";

  return (
    <div className='aboutUs'>
      <PageHeader header1={header1} paragraph1={paragraph1} imgArr="aboutUs" />
            <div className='subPageContainer'>
                <StyledBlock fileName={styledBlockFileName}/>
            <NYKAYH2O id='Distribution' direction={false} title={NYKtitle} img={FireFighter} alt="firefighter in uniform" paragraph={NYKparagraph}/>
                <StyledBlock fileName={styledBlockFileName2}/>
            </div>

        <CardLinks urlDomain={urlDomain} fileName={linksFileName}/>

      </div> 
  )
}

export default AboutUs
