import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './StyledBlockCSS.css';
import { Link } from 'react-router-dom';

const StyledBlock = (props) => {
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      if (props.selectionList !== undefined) {
        Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { 'fileName': `StyledBlocks/MultiSelection/${props.selectionList}.json` }).then((response) => {
          const result = response.data;
          setList(result);
        });
      }
    };

    loadData();
  }, [props.selectionList])

  useEffect(() => {
    const loadData = async () => {
      if (props.block === undefined) {
        Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { 'fileName': props.fileName })
          .then((response) => {
            const result = response.data;
            setBlocks(result);
          })
      } else {
        setBlocks(props.block)
      }
    };
    loadData();
  }, [props.block, props.fileName])

  const [blocks, setBlocks] = useState([]);

  return (
    <div className='styled-Block bg'>
      {blocks.map((styledBlock, key) => (
        <div
          className={`styled-Block ${styledBlock?.multiSelection !== undefined && styledBlock?.multiSelection !== false ? 'multiSelect' : ''}`}
          key={key}
          style={{ minHeight: styledBlock?.componentHeight === '' || styledBlock?.componentHeight === undefined ? '650px' : styledBlock?.componentHeight }}>
          {(styledBlock?.multiSelection === undefined || styledBlock?.multiSelection === false) && (props.historyItem === false || props.historyItem === undefined) ? (<>
            {styledBlock?.endtext === undefined || styledBlock?.endtext === false ? (<>
              <div className={`styled-TextBlock-bg ${styledBlock?.direction}`}>

                {/* Title */}
                {styledBlock?.title !== "" && styledBlock?.title !== undefined ? (
                  <h2 className={`styled-TextBlock title ${styledBlock?.direction}`}>{styledBlock?.title}</h2>
                ) : ''}

                {/* Image */}
                {styledBlock?.img !== "" && styledBlock?.img !== undefined ? (
                  <div className={`styled-ImageBlock ${styledBlock?.direction} in-text`}>
                    <img
                      loading='lazy'
                      src={`https://searcywaterutilitiesservice.onrender.com/images/${styledBlock?.img}`}
                      alt={styledBlock?.alt}
                    />
                    {styledBlock?.img_text !== "" && styledBlock?.img_text !== undefined ? <p>{styledBlock?.img_text}</p> : ''}
                  </div>
                ) : ''}

                {/* Text */}
                {styledBlock?.texts?.map((text, key) => (
                  <div key={key}>
                    <p className={`styled-TextBlock paragraph ${styledBlock?.direction}`}>
                      {text.map((obj, key) => (
                        <span key={key}>
                          {obj.text}
                          {obj.link_address !== undefined && obj.link_address !== "" && obj.link_name !== undefined && obj.link_name !== "" ? <Link to={obj.link_address}>{obj.link_name}</Link> : ''}
                          {obj.tag !== undefined && obj.tag !== "" && obj.tag !== 'br' && obj.tag !== 'a' && obj.tag !== 'Link' && obj.tag_content !== undefined && obj.tag_content !== "" ? (
                            <obj.tag>
                              {obj.tag === 'ul' || obj.tag === 'ol' ? (
                                obj.tag_content.map((data, key) => (
                                  <li key={key}>{data}</li>
                                ))
                              ) : obj.tag_content}
                            </obj.tag>
                          )
                            : obj.tag === 'br' ? (<br />)
                              : obj.tag === 'a' ? (
                                <a
                                  href={obj.tag_meta.link}
                                  target={obj.tag_meta.target}
                                  rel={obj.tag_meta.rel}>
                                  {obj.tag_content}
                                </a>)
                                : obj.tag === 'Link' ? (
                                  <Link
                                    to={obj.tag_meta.link}>
                                    {obj.tag_content}
                                  </Link>)
                                  :''}
                        </span>
                      ))}
                    </p></div>
                ))}
              </div>

              {/* SubText */}
              {styledBlock?.subtext !== "" && styledBlock?.subtext !== undefined ? (
                <p className={`styled-TextBlock subtext ${styledBlock?.direction} ${styledBlock?.altWidth_1 === true ? 'altWidth_1' : ''} ${styledBlock?.altWidth_2 === true ? 'altWidth_2' : styledBlock?.altWidth_2 === false ? 'undoAltWidth_1' : ''} in-text`}>{styledBlock?.subtext}</p>
              ) : ''}

              {/* Under 850px - START */}
              {(styledBlock?.img !== "" && styledBlock?.img !== undefined) || (styledBlock?.subtext !== "" && styledBlock?.subtext !== undefined) ? (
                <div className='out-text'>

                  {/* Image */}
                  {styledBlock?.img !== "" && styledBlock?.img !== undefined ? (
                    <div className='styled-ImageBlock'>
                      <img
                        loading='lazy'
                        src={`https://searcywaterutilitiesservice.onrender.com/images/${styledBlock?.img}`}
                        alt={styledBlock?.alt}
                      />
                      {styledBlock?.img_text !== "" && styledBlock?.img_text !== undefined ? <p>{styledBlock?.img_text}</p> : ''}
                    </div>
                  ) : ''}

                  {/* SubText */}
                  {styledBlock?.subtext !== "" && styledBlock?.subtext !== undefined ? (
                    <p className='styled-TextBlock subtext'>{styledBlock?.subtext}</p>
                  ) : ''}
                </div>

                /* Under 850px - END */
              ) : ''}
            </>) : (<>
              <p className='styled-TextBlock subtext endText'>Searcy Water Utilities<br />Your Water. Our Responsibility</p>
            </>)}
          </>) : (
            <>
              {/* MultiSelection List */}
              {/* Selector */}
              {styledBlock?.multiSelection === true ? (
                <div className='multiSelectionList'>
                  {list.map((selection, key) => (
                    <div style={{ marginBottom: '25px' }}
                      key={key}>
                        {selection.noData ? (
                          <div>
                          <img
                            loading='lazy'
                            className='multiSelectionListImgs disabled'
                            src={`https://searcywaterutilitiesservice.onrender.com/images/StyledBlocks/ListImgs/${selection.smImg}`}
                            alt={selection.alt}
                          />
                        </div>
                        ): (
                        <Link to={`#${selection?.link}`}>
                        <img
                          loading='lazy'
                          className='multiSelectionListImgs'
                          src={`https://searcywaterutilitiesservice.onrender.com/images/StyledBlocks/ListImgs/${selection.smImg}`}
                          alt={selection.alt}
                          onClick={() => {
                            setSelectedItem(key)
                          }}
                        />
                      </Link>)}
                        
                      <p className='multiSelectionListText'>{selection.name}</p>
                      {selection.name !== undefined ? 
                      <p className='multiSelectionListText multiSelectionListTitleText'>{selection.title}</p>
                      : null }
                    </div>
                  ))}
                </div>
              ) : ''}

              {/* Selected/History Item Styled TextBlock Background */}
              <div className={`styled-TextBlock-bg ${styledBlock.direction}`}>
                {/* Selected/History Item Title */}
                {styledBlock?.multiSelection || styledBlock?.title !== '' || styledBlock?.title !== undefined ? (
                  <h2 className='styled-TextBlock title center' id={list[selectedItem]?.link}>
                    {styledBlock?.multiSelection ? `${list[selectedItem]?.name}` : `${styledBlock?.title}`}</h2>
                ) : ''}

                {list[selectedItem]?.title !== "" && list[selectedItem]?.title !== undefined ? (
                  <p className='multiSelectionTitle'>{list[selectedItem]?.title}</p>
                ) : ''}
                {/* FIRST Paragraph */}
                <p className='styled-TextBlock paragraph right'>{list[selectedItem] !== undefined ?
                  list[selectedItem]?.about[0]?.map((obj, key) => (
                    <span key={key}>
                      {obj.text}
                      {obj.link_address !== undefined && obj.link_address !== "" && obj.link_name !== undefined && obj.link_name !== "" ? <Link to={obj.link_address}>{obj.link_name}</Link> : ''}
                      {obj.tag !== undefined && obj.tag !== "" && obj.tag !== 'br' && obj.tag !== 'a' && obj.tag_content !== undefined && obj.tag_content !== "" ? (
                        <obj.tag>
                          {obj.tag === 'ul' || obj.tag === 'ol' ? (
                            obj.tag_content.map((data, key) => (
                              <li key={key}>{data}</li>
                            ))
                          ) : obj.tag_content}
                        </obj.tag>
                      )
                        : obj.tag === 'br' ? (<br />)
                          : obj.tag === 'a' ? (
                            <a
                              href={obj.tag_meta.link}
                              target={obj.tag_meta.target}
                              rel={obj.tag_meta.rel}>
                              {obj.tag_content}
                            </a>)
                                : obj.tag === 'Link' ? (
                                  <Link
                                    to={obj.tag_meta.link}>
                                    {obj.tag_content}
                                  </Link>)
                            : ''}
                    </span>
                  ))
                  : props.historyItem ? styledBlock?.texts[0][0]?.text : ''}</p>
                {/* Image */}
                {list[selectedItem] !== undefined ? (
                  <div className='styled-ImageBlock right in-text'>
                    <img
                      loading='lazy'
                      src={`https://searcywaterutilitiesservice.onrender.com/images/StyledBlocks/${list[selectedItem]?.img}`}
                      alt={list[selectedItem]?.alt}
                    />
                    <p>{list[selectedItem]?.name}</p>
                  </div>
                ) : (
                  <div className='styled-ImageBlock right in-text'>
                    <img
                      loading='lazy'
                      src={`https://searcywaterutilitiesservice.onrender.com/images/${styledBlock?.img}`}
                      alt={styledBlock?.alt}
                    />
                    <p>{styledBlock?.img_text}</p>
                  </div>
                )}

                <div data-type='selection_img_container' className='out-text'>
                  {list[selectedItem] !== undefined ? (
                    <div className='styled-ImageBlock'>
                      <img
                        data-type='selection_img_img'
                        loading='lazy'
                        src={`https://searcywaterutilitiesservice.onrender.com/images/StyledBlocks//${list[selectedItem]?.img}`}
                        alt={list[selectedItem]?.alt}
                      />
                      <p>{list[selectedItem]?.name}</p>
                    </div>
                  ) : (
                    <div className='styled-ImageBlock'>
                      <img
                        data-type='selection_img_img'
                        loading='lazy'
                        src={`https://searcywaterutilitiesservice.onrender.com/images/${styledBlock?.img}`}
                        alt={styledBlock?.alt}
                      />
                      <p>{styledBlock?.img_text}</p>
                    </div>
                  )}
                </div>

                {/* Additional Paragraphs */}
                {styledBlock?.multiSelection ? (
                  <>
                    {list[selectedItem]?.about?.map((about, key) => (
                      <div key={key}>
                        {key > 0 ? <p className='styled-TextBlock paragraph right' >{
                          about.map((obj, key) => (
                            <span key={key}>
                              {obj.text}
                              {obj.link_address !== undefined && obj.link_address !== "" && obj.link_name !== undefined && obj.link_name !== "" ? <Link to={obj.link_address}>{obj.link_name}</Link> : ''}
                              {obj.tag !== undefined && obj.tag !== "" && obj.tag !== 'br' && obj.tag !== 'a' && obj.tag_content !== undefined && obj.tag_content !== "" ? (
                                <obj.tag>
                                  {obj.tag === 'ul' || obj.tag === 'ol' ? (
                                    obj.tag_content.map((data, key) => (
                                      <li key={key}>{data}</li>
                                    ))
                                  ) : obj.tag_content}
                                </obj.tag>
                              )
                                : obj.tag === 'br' ? (<br />)
                                  : obj.tag === 'a' ? (
                                    <a
                                      href={obj.tag_meta.link}
                                      target={obj.tag_meta.target}
                                      rel={obj.tag_meta.rel}>
                                      {obj.tag_content}
                                    </a>)
                                    : ''}
                            </span>
                          ))
                        }</p> : ''}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {styledBlock?.texts?.map((text, key) => (
                      <div key={key}>
                        {key > 0 ? <p className='styled-TextBlock paragraph right' >{text[0].text}</p> : ''}
                      </div>
                    ))}
                  </>
                )}


              </div>
            </>)}
        </div>
      ))}
    </div>
  )
}

StyledBlock.propTypes = {
  fileName: PropTypes.string,
  selectionList: PropTypes.string,
  imgLocation: PropTypes.string,
  block: PropTypes.array,
  historyItem: PropTypes.bool,
}
export default StyledBlock
