import React from 'react'
import './LoaderCSS.css'

const Loader = () => {
  return (
      <div className="spinner"></div>
  )
}

export default Loader
