import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Button } from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

const GetPDF = (props) => {


  const [numPages, setNumPages] = useState(null);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [pageNumber, setPageNumber] = useState(props.page);
  const [recent, setRecent] = useState('');
  const [pdfFile, setPDFFile] = useState({});


  useEffect(() => {
    const loadData = async () => {
      try {
        await Axios.post("https://searcywaterutilitiesservice.onrender.com/getData", { 'fileName': props.fileName }).then((response) => {
          const result = response.data;
          const reports = result[result.length - 1];
          setRecent(reports.file);
        })
      } catch (err) {
        console.log(err)
      }
    };
    loadData()
  }, [props.fileName]);

  useEffect(() => {
    setPageNumber(props.page);
    const loadPDF = async () => {
      await Axios.get("https://searcywaterutilitiesservice.onrender.com/getPDF", {
        responseType: 'blob',
        params: {
          recent: props.recent
        }
      }).then((response) => {
        const file = response.data;
        let blob = new Blob([file], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        setPDFFile(url);
      }).catch(error => {
        console.log(error);
      });
    }
    if (recent !== "") {
      loadPDF();
    }
  }, [props.page, props.recent, recent])

  useEffect(() => {
    if (pageNumber > 1) {
      setDisablePrev(false);
      setDisableNext(false);
      if (pageNumber >= numPages) {
        setDisableNext(true);
      }
    } else if (pageNumber <= 1) {
      setDisablePrev(true);
      setDisableNext(false);
    }
  }, [pageNumber, numPages])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    const page = pageNumber + offset;
    (page < 1) ? setPageNumber(1) : setPageNumber(page);
  }


  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };
  return (
    <div>
      {props.controls ?
        (
        <div className='PDF-btn-container'>
        <Button sx={{ margin: '20px' }} variant='contained' disabled={disablePrev} onClick={() => { changePage(-1) }}>Previous</Button>
        <Button sx={{ margin: '20px' }} variant='contained' onClick={() => { window.open(pdfFile) }}>Download</Button>
        <Button sx={{ margin: '20px' }} variant='contained' disabled={disableNext} onClick={() => { changePage(1) }}>Next</Button>
    </div>
        ) :
        <></>
      }
      <Box
        display="flex"
        justifyContent='center'
      >
        <Document
          file={{
            'url': pdfFile.toString()
          }}
          options={(options)}
          onLoadSuccess={onDocumentLoadSuccess}
          error={<Loader />}
          loading={<Loader />}
        >
          <Page renderAnnotationLayer={false} pageNumber={pageNumber} renderTextLayer={false} width={props.size} loading={<Loader />} />
        </Document>
      </Box>
    </div>
  )
}

GetPDF.propTypes = {
  fileName: PropTypes.string.isRequired,
  controls: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  recent: PropTypes.string,
}
export default GetPDF